import { FileDownload } from '@mui/icons-material';
import { Button, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClassApi } from 'api/class.api';
import { TimeSlotApi } from 'api/timeslot.api';
import { TimeTableApi } from 'api/timetable.api';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs from 'dayjs';
import { populateSchedule } from 'library/services/ViewTimeTable.service';
import { CollectionApiResponse, IRI, ParamsType } from 'library/types/Common';
import { TimeSlot } from 'library/types/TimeSlot';
import {
  CollectionDetailedTimeTable,
  DaySchedule,
  Days,
  SlotDetails,
} from 'library/types/TimeTable';
import { UserRoles } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DownloadFile } from '../Tables/CellComponents/DownloadFile';
import { parseHourToTimezone } from 'library/helpers/helper';

export const ViewDetailedTimeTableModule = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const theme = useTheme();
  const location = useLocation();

  const state = location.state as {
    saptamana: string;
  };

  const { saptamana } = state || {};

  const account = userContext.state.account!;
  const userRole = account.selectedView;

  const { getDetailedTimeTable } = TimeTableApi();
  const { getAvailableClasses } = ClassApi();
  const { getTimeSlot } = TimeSlotApi();

  const [selectedSlot, setSelectedSlot] = useState<SlotDetails | null>(null);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);

  const [classes, setClasses] = useState<
    Array<{
      id: number;
      classCode: string;
      studyYear: number;
    }>
  >([]);
  const [selectedClass, setSelectedClass] = useState<{
    id: number;
    classCode: string;
    studyYear: number;
  }>({
    id: -1,
    classCode: '',
    studyYear: -1,
  });

  const [weeks, setWeeks] = useState<Array<string>>([]);
  const [selectedWeek, setSelectedWeek] = useState<string>('');
  const [weeklyDetailedScheduleJson, setWeeklyDetailedScheduleJson] = useState<{
    [key: string]: {
      [key in Days]: {
        date: string;
        schedule: DaySchedule;
      };
    };
  }>();

  const [timeSlots, setTimeSlots] = useState<
    Array<{
      id: number;
      start: string;
      end: string;
      '@id': IRI;
    }>
  >();

  useEffect(() => {
    if (userRole === UserRoles.ROLE_PARINTE) {
      (async () => {
        const availableClasses = await getAvailableClasses();
        setClasses(
          availableClasses
            .filter((e) => !Array.isArray(e))
            .map((e) => ({
              id: e.id,
              classCode: e.codClasa,
              studyYear: parseInt(
                e.anStudiu.split('/')[e.anStudiu.split('/').length - 1]
              ),
            }))
        );
      })();
    } else {
      getViewDetailedTimeTableResources();
    }
  }, []);

  useEffect(() => {
    if (selectedClass.id === -1) return;
    getViewDetailedTimeTableResources({
      'orar.clasa.codClasa': selectedClass.classCode,
    });
  }, [selectedClass.id]);

  const getViewDetailedTimeTableResources = async (
    detailedTimeSlotsParams?: ParamsType
  ) => {
    const response = (await getTimeSlot()) as CollectionApiResponse<TimeSlot>;
    const timeSlots = response['hydra:member'];

    setTimeSlots(
      timeSlots
        .map((e) => ({
          id: e.id,
          start: parseHourToTimezone(e.oraStart),
          end: parseHourToTimezone(e.oraSfarsit),
          ['@id']: e['@id'],
        }))
        .sort((a, b) => {
          const [hoursA, minutesA] = a.start.split(':').map(Number);
          const [hoursB, minutesB] = b.start.split(':').map(Number);
          return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
        })
    );

    const detailedTimeSlots = (await getDetailedTimeTable({
      params: detailedTimeSlotsParams,
    })) as CollectionApiResponse<CollectionDetailedTimeTable>;

    const parsedData = detailedTimeSlots['hydra:member']
      .map((e) => ({
        ...e,
        data: dayjs(e.data).format('YYYY-MM-DD'),
      }))
      .sort((a, b) => {
        return dayjs(a.data).valueOf() - dayjs(b.data).valueOf();
      });

    const { weeks, currentWeek } = populateSchedule(parsedData);
    setWeeklyDetailedScheduleJson(weeks);
    setWeeks(Object.keys(weeks));
    if (saptamana) {
      setSelectedWeek(saptamana);
    } else {
      setSelectedWeek(currentWeek);
    }
  };

  const handleCloseDialog = (): void => {
    setSelectedSlot(null);
  };

  const days = Object.values(Days).filter((day) => day !== Days.Duminica);
  const daysCount = days.length;
  const columnWidth = daysCount > 0 ? 90 / daysCount : 100;

  return (
    <div className="overflow-x-auto w-full">
      <div className="flex gap-3">
        {!!classes.length && (
          <RegularSelect
            tailwindContainerClasses="w-48"
            options={classes.map((e) => ({
              label: e.classCode.toString(),
              value: e.id,
            }))}
            onChange={(e) => {
              setSelectedClass(classes.find((el) => el.id === e)!);
            }}
            selectProps={{
              label: tModules('schoolAdmin.timeTable.class'),
              value: selectedClass.id === -1 ? '' : selectedClass.id,
              fullWidth: true,
            }}
          />
        )}
        {/* {!!modules.length && (
          <RegularSelect
            tailwindContainerClasses="w-80"
            options={modules.map((e) => ({
              label: e.details.toString(),
              value: e.id,
            }))}
            onChange={(e) => {
              setSelectedModule(modules.find((el) => el.id === e)!);
            }}
            selectProps={{
              label: 'Selecteaza modul',
              value: selectedModule.id === -1 ? '' : selectedModule.id,
              fullWidth: true,
            }}
          />
        )} */}
        {!!weeks.length && (
          <RegularSelect
            tailwindContainerClasses="w-80"
            options={weeks.map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={(e) => {
              setSelectedWeek(e);
            }}
            selectProps={{
              label: tModules('schoolAdmin.timeTable.week'),
              value: selectedWeek,
              fullWidth: true,
            }}
          />
        )}
      </div>
      {weeklyDetailedScheduleJson && timeSlots && (
        <table
          style={{ backgroundColor: 'rgb(241, 245, 248)' }}
          className="min-w-full bg-white border-collapse select-none"
        >
          <thead className="text-white">
            <tr>
              <th
                style={{ width: `10%` }}
                className="bg-gray-800 w-24 py-3 px-2 uppercase font-semibold text-sm text-center border border-gray-300"
              >
                {tModules('schoolAdmin.timeTable.time')}
              </th>
              {days.map((day) => {
                return (
                  <th
                    key={day}
                    style={{ width: `${columnWidth}%` }}
                    className="bg-gray-800 py-3 px-4 uppercase font-semibold text-sm text-center border border-gray-300"
                  >
                    <div className="flex flex-col">
                      <div>{day}</div>
                      {weeklyDetailedScheduleJson && (
                        <>
                          {dayjs(
                            weeklyDetailedScheduleJson?.[selectedWeek]?.[day]
                              .date
                          ).format('DD.MM.YYYY')}
                        </>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-gray-700 bg-white">
            {selectedWeek &&
              timeSlots.map((timeSlot) => {
                const { id, start, end } = timeSlot;
                return (
                  <tr key={id} className="align-middle">
                    <td
                      style={{ width: `10%`, height: '100px' }}
                      className="py-2 px-2 border-b border-r border-gray-200 text-center"
                    >
                      {start} - {end}
                    </td>
                    {days.map((day) => {
                      const slot =
                        weeklyDetailedScheduleJson[selectedWeek][day].schedule[
                          id
                        ];
                      return (
                        <td
                          key={day}
                          onMouseEnter={() => {
                            if (slot && slot.orarDetaliatId)
                              setHoveredElement(slot.orarDetaliatId);
                          }} // Set hover state to true
                          onMouseLeave={() => setHoveredElement(null)} // Reset hover state to false
                          style={{
                            width: `${columnWidth}%`,
                            height: '100px',
                            backgroundColor:
                              slot && slot.orarDetaliatId === hoveredElement
                                ? theme.palette.primary.light
                                : '',
                          }}
                          className={`${
                            slot &&
                            [
                              UserRoles.ROLE_INVATATOR,
                              UserRoles.ROLE_PROFESOR,
                              UserRoles.ROLE_ELEV,
                            ].includes(userRole)
                              ? 'cursor-pointer'
                              : 'cursor-default'
                          } py-2 px-4 border-b border-r border-gray-200 text-center align-middle`}
                          onClick={() => {
                            if (
                              !slot ||
                              ![
                                UserRoles.ROLE_INVATATOR,
                                UserRoles.ROLE_PROFESOR,
                                UserRoles.ROLE_ELEV,
                              ].includes(userRole)
                            )
                              return;
                            setSelectedSlot({
                              day,
                              slot,
                              date: weeklyDetailedScheduleJson?.[
                                selectedWeek
                              ]?.[day].date,
                            });
                            // handleOpenDialog({
                            //   slot,
                            //   day,
                            // });
                          }}
                        >
                          {slot && slot.orarIri ? (
                            <div>
                              <div className="font-semibold">
                                {slot.materie?.tipMaterie}
                              </div>
                              <div>{slot.profesor?.numeComplet}</div>
                              <div className="text-gray-600">
                                {slot.clasa?.codClasa}
                              </div>
                            </div>
                          ) : (
                            <div className="cursor-default">
                              {tModules('schoolAdmin.timeTable.N/A')}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {selectedSlot && (
        <Dialog fullWidth open={!!selectedSlot} onClose={handleCloseDialog}>
          <DialogTitle className="flex justify-center">
            <div className="text-2xl font-bold">
              {tModules('schoolAdmin.timeTable.timeTableDetails')}
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="flex flex-col">
                {/* <div className="flex flex-col items-start"> */}
                <div>
                  <span className="font-semibold">
                    {tModules('schoolAdmin.timeTable.class')}:
                  </span>{' '}
                  {selectedSlot.slot.clasa?.codClasa}
                </div>
                <div>
                  <span className="font-semibold">
                    {tModules('schoolAdmin.timeTable.day')}:
                  </span>{' '}
                  {selectedSlot.day}
                </div>
                <div>
                  <span className="font-semibold">
                    {tModules('schoolAdmin.timeTable.timeSlot')}:
                  </span>{' '}
                  {selectedSlot.slot.oraStart +
                    ' - ' +
                    selectedSlot.slot.oraSfarsit}
                </div>
                {selectedSlot.date && (
                  <div>
                    <span className="font-semibold">
                      {tModules('schoolAdmin.timeTable.date')}:
                    </span>{' '}
                    {dayjs(selectedSlot.date).format('DD.MM.YYYY')}
                  </div>
                )}
                {selectedSlot.slot.profesor?.numeComplet && (
                  <div>
                    <div>
                      <span className="font-semibold">
                        {tModules('schoolAdmin.timeTable.professor')}:
                      </span>{' '}
                      {selectedSlot.slot.profesor?.numeComplet}
                    </div>
                    <div>
                      <span className="font-semibold">
                        {tModules('schoolAdmin.timeTable.subject')}:
                      </span>{' '}
                      {selectedSlot.slot.materie?.tipMaterie}
                    </div>
                  </div>
                )}
                {selectedSlot.slot.lectie && (
                  <div>
                    <div>
                      <span className="font-semibold">
                        {tModules('schoolAdmin.timeTable.lessonSubject')}:
                      </span>{' '}
                      {selectedSlot.slot.lectie.subiect}
                    </div>
                    <div>
                      <span className="font-semibold">
                        {tModules('schoolAdmin.timeTable.zoomLink')}:
                      </span>{' '}
                      <a
                        href={selectedSlot.slot.linkZoom || ''}
                        target="_blank"
                        className="cursor-pointer text-blue-500 hover:underline focus:outline-none"
                      >
                        {selectedSlot.slot.linkZoom}
                      </a>
                    </div>
                    <div>
                      <span className="font-semibold">
                        {tModules('schoolAdmin.timeTable.fileLink')}:
                      </span>{' '}
                      <DownloadFile
                        fileUrl={selectedSlot.slot.lectie.fisier.contentUrl}
                        fileName={selectedSlot.slot.lectie.fisier.urlPath}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>
              {tCommon('inputs.closeButton')}
            </Button>
            {[UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR].includes(
              userRole
            ) && (
              <Button
                onClick={() => {
                  const { slot } = selectedSlot;
                  navigate('/lesson/detailed-timetable', {
                    state: {
                      saptamana: selectedWeek,
                      materie: {
                        '@id': slot.materie!['@id'],
                        detaliiMaterie:
                          slot.materie?.tipMaterie +
                          ' - ' +
                          slot.profesor!.numeComplet,
                      },
                      tipMaterie: {
                        '@id': slot.materie?.tipMaterieIri,
                      },
                      clasa: {
                        '@id': slot.clasa!['@id'],
                        id: slot.clasa!.id,
                        codClasa: slot.clasa!.codClasa,
                      },
                      detaliiTemporale:
                        selectedSlot.day +
                        ': ' +
                        dayjs(slot.oraStart).format('HH:mm') +
                        ' - ' +
                        dayjs(slot.oraSfarsit).format('HH:mm') +
                        ', ' +
                        dayjs(selectedSlot.date).format('DD.MM.YYYY'),
                      orarDetaliatId: slot.orarDetaliatId,
                    },
                  });
                }}
              >
                {tModules('schoolAdmin.timeTable.addLesson')}
              </Button>
            )}
            {[UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR].includes(
              userRole
            ) && (
              <Button
                onClick={() => {
                  const { slot } = selectedSlot;
                  navigate(
                    userRole === UserRoles.ROLE_PROFESOR
                      ? '/catalogue/grade'
                      : '/catalogue/calificativ',
                    {
                      state: {
                        saptamana: selectedWeek,
                        anStudiu: slot.anStudiu,
                        modulIri: slot.modulIri,
                        materie: {
                          '@id': slot.materie!['@id'],
                          detaliiMaterie:
                            slot.materie?.tipMaterie +
                            ' - ' +
                            slot.profesor!.numeComplet,
                        },
                        clasa: {
                          '@id': slot.clasa!['@id'],
                          id: slot.clasa!.id,
                          codClasa: slot.clasa!.codClasa,
                          anStudiu: parseInt(slot.clasa!.codClasa),
                        },
                        detaliiTemporale:
                          selectedSlot.day +
                          ': ' +
                          dayjs(slot.oraStart).format('HH:mm') +
                          ' - ' +
                          dayjs(slot.oraSfarsit).format('HH:mm') +
                          ', ' +
                          dayjs(selectedSlot.date).format('DD.MM.YYYY'),
                        isGrade: true,
                        orarIri: slot.orarIri,
                        orarDetaliatIri: slot.orarDetaliatIri,
                      },
                    }
                  );
                }}
              >
                {tModules(
                  userRole === UserRoles.ROLE_PROFESOR
                    ? 'schoolAdmin.timeTable.addGrade'
                    : 'schoolAdmin.timeTable.addCalificativ'
                )}
              </Button>
            )}
            {[UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR].includes(
              userRole
            ) && (
              <Button
                onClick={() => {
                  const { slot } = selectedSlot;
                  navigate('/catalogue/absence', {
                    state: {
                      anStudiu: slot.anStudiu,
                      materie: {
                        '@id': slot.materie!['@id'],
                        detaliiMaterie:
                          slot.materie?.tipMaterie +
                          ' - ' +
                          slot.profesor!.numeComplet,
                      },
                      clasa: {
                        '@id': slot.clasa!['@id'],
                        id: slot.clasa!.id,
                        codClasa: slot.clasa!.codClasa,
                      },
                      modulIri: slot.modulIri,
                      detaliiTemporale:
                        selectedSlot.day +
                        ': ' +
                        dayjs(slot.oraStart).format('HH:mm') +
                        ' - ' +
                        dayjs(slot.oraSfarsit).format('HH:mm') +
                        ', ' +
                        dayjs(selectedSlot.date).format('DD.MM.YYYY'),
                      isGrade: false,
                      orarIri: slot.orarIri,
                      orarDetaliatIri: slot.orarDetaliatIri,
                    },
                  });
                }}
              >
                {tModules('schoolAdmin.timeTable.addAbsence')}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
