import dayjs from 'dayjs';
import { chunkArray } from 'library/helpers/helper';
import { CatalogReportProps } from 'library/types/Reports';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import './PrintCatalogReport.css';

class CatalogReport extends React.Component<CatalogReportProps> {
  render() {
    const { catalogReportInfo } = this.props;
    const {
      schoolName,
      schoolLocalitate,
      schoolJudet,
      codClasa,
      anInceput,
      anSfarsit,
      anStudiu,
      director,
      cadruDidacticResponsabil,
      cadreSiDiscipline,
      denumireMinister,
      situatieElevi,
      cadreSiDisciplineMedii,
      reportDate,
      selectedSecretar,
      studentsCatalog,
    } = catalogReportInfo;
    const numberOfSubjects = Object.keys(studentsCatalog[0].materiiInfo).length;

    const cadreSiDisciplineMediiChunks = chunkArray(cadreSiDisciplineMedii, 12);
    const cadreSiDisciplineChunks = chunkArray(cadreSiDiscipline, 12);
    const studentsCatalogChunks = chunkArray(studentsCatalog, 2);
    const datePersonaleChunks = chunkArray(studentsCatalog, 10);

    const convertDate = (date: string) => {
      const updatedDate = dayjs(date).format('MM-DD');
      const [month, day] = updatedDate.split('-');
      const romanMonths = [
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
        'X',
        'XI',
        'XII',
      ];
      const romanMonth = romanMonths[parseInt(month) - 1];
      return `${day}.${romanMonth}`;
    };

    const fetchAbsCalif = (
      studentIndex: number,
      subjectIndex: number,
      grades: boolean
    ) => {
      if (grades) {
        const subjectIds = Object.keys(
          studentsCatalog[studentIndex].materiiInfo
        );
        const note =
          studentsCatalog[studentIndex].materiiInfo[subjectIds[subjectIndex]]
            .note;
        return (
          <div className="flex flex-col">
            {!!note?.length &&
              note.map((nota, index) => (
                <div key={index} className="grade-text">
                  {nota.nota}/{convertDate(nota.data)}
                </div>
              ))}
          </div>
        );
      } else {
        const subjectIds = Object.keys(
          studentsCatalog[studentIndex].materiiInfo
        );
        const absente =
          studentsCatalog[studentIndex].materiiInfo[subjectIds[subjectIndex]]
            .absente;
        return (
          <div className="flex flex-col">
            {!!absente?.length &&
              absente.map((absenta, index) => (
                <div key={index} className="absence-text">
                  {convertDate(absenta)}
                </div>
              ))}
          </div>
        );
      }
    };

    const fetchAverage = (
      studentIndex: number,
      rowIndex: number,
      subjectIndex: number
    ) => {
      const subjectIds = Object.keys(studentsCatalog[studentIndex].materiiInfo);
      const subject =
        studentsCatalog[studentIndex].materiiInfo[subjectIds[subjectIndex]];
      switch (rowIndex) {
        case 0:
          return subject.medie;
        case 1:
          return subject.medie_corigenta;
        case 2:
          return subject.medie_finala;
      }
    };

    // function to convert number to roman numerals in typescript
    function toRoman(num: number) {
      const lookup: { [key: string]: number } = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      };
      let roman = '';
      let i;
      for (i in lookup) {
        while (num >= lookup[i]) {
          roman += i;
          num -= lookup[i];
        }
      }
      return roman;
    }

    const getTypeOfInvatamant = (withAdditionalDetails?: boolean) => {
      if (anStudiu < 5) {
        return `(învățământ primar${
          withAdditionalDetails ? ' - clasele I-IV' : ''
        })`;
      } else if (anStudiu < 9) {
        return `(învățământ gimnazial${
          withAdditionalDetails ? ' - clasele V-VIII' : ''
        })`;
      } else {
        return `(învățământ liceal${
          withAdditionalDetails ? ' - clasele IX-XIII' : ''
        })`;
      }
    };

    const getHeadmasterTitle = () => {
      switch (anStudiu) {
        case 1:
        case 2:
        case 3:
        case 4:
          return 'Învățător';
        default:
          return 'Diriginte';
      }
    };

    return (
      <div className="print-container">
        <section className="page first-page" aria-label="first-page">
          <div className="content mt-12">
            <div className="box flex flex-col items-center">
              <div className="ministry-text">
                {denumireMinister?.toUpperCase()} *
              </div>
              <div className="divider"></div>
              <div className="school-name-section">
                <div className="school-name">{schoolName}</div>
                <div className="sub-school-name-text">
                  (unitatea de învățămant)
                </div>
              </div>
              <div className="location-section flex-start">
                <div className="city">Localitatea: {schoolLocalitate}</div>
                <div className="county">Judetul: {schoolJudet}</div>
              </div>
              <div className="big-title">CATALOGUL</div>
              <div className="details-section">
                <div className="detail-text">
                  CLASEI{' '}
                  <span className="undeline">
                    a {toRoman(parseInt(codClasa))}-a{' '}
                    {codClasa[codClasa.length - 1]} MAG
                  </span>
                </div>
                <div className="detail-text">
                  ANUL ȘCOLAR{' '}
                  <span className="undeline">
                    {anInceput} / {anSfarsit}
                  </span>
                </div>
                <div className="sub-detail-text">{getTypeOfInvatamant()}</div>
              </div>
            </div>
            <div className="sub-box-text">
              * denumirea curentă a ministerului
            </div>
          </div>
          <div className="page-count">
            1/{5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
            Catalogul clasei a {toRoman(parseInt(codClasa))}-a{' '}
            {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
          </div>
        </section>
        <section className="page second-page new-page" aria-label="second-page">
          <div className="content mt-12">
            <div className="title text-center">
              <div className="title-text">NORME</div>
              <div className="title-text">pentru completarea și folosirea</div>
              <div className="title-text">catalogului clasei</div>
            </div>
            <div className="page-content text-justify">
              <div className="page-content-text">
                Catalogul este un document oficial. De completarea lui răspunde
                învăţătorul/ institutorul/ profesorul pentru învăţământ primarşi
                directorul şcolii. Catalogul clasei devine document şcolar cu
                regim special, după completarea cu datele de identificare ale
                elevilor.
              </div>
              <div className="page-content-text">
                Pentru fiecare clasă se foloseşte un catalog. Acolo unde sunt
                clase paralele, pe cataloagele acestor clase se adaugă o literă
                distinctivă (exemplu: clasa a III-a A, clasa a III-a B etc.). În
                cazul claselor care funcționează în regim simultan se foloseşte
                un singur catalog pentru clasele aferente.
              </div>
              <div className="page-content-text">
                În catalog se trec, în ordine alfabetică, la începutul anului
                şcolar, toţi elevii înscrişi în clasa respectivă.
              </div>
              <div className="page-content-text">
                Învăţătorul/ institutorul/ profesorul pentru învăţământ primar
                este obligat ca în primele zile după deschiderea anuluişcolarsă
                întocmească catalogul clasei, completându-l cu datele cerute de
                formular (numele şi prenumele cadrelor didactice care predau la
                clasa respectivă, numele şi prenumele elevilorşi datele
                personale ale acestora).
              </div>
              <div className="page-content-text">
                Învăţătorul/institutorul/profesorul pentru învăţământ primar are
                obligaţia să prezinte profesorilor care predau la clasa
                respectivă normele de utilizare a catalogului.
              </div>
              <div className="page-content-text">
                La sfârşitul anului şcolar se vor completa datele din tabelul
                ”Situaţia generală asupramişcăriişi frecvenței elevilor și a
                rezultatelor obținute”.
              </div>
              <div className="page-content-text">
                În cazul elevilor transferaţi sau care nu mai frecventează
                cursurile din diferite motive,
                învăţătorul/institutorul/profesorul pentru învăţământul primar
                face menţiunea respectivă în rubrica rezervată în acest scop.
                Această menţiune se face şi în registrul de înscriere şi de
                evidenţă a elevilor.
              </div>
              <div className="page-content-text">
                Învăţătorii/institutorii/profesorii pentru învăţământul primar
                sunt obligaţi să noteze zilnic absenţele elevilor în rubricile
                rezervate la fiecare disciplină. Exemplu: IX: 15, 17, 18 etc.;
                XII: 4, 5, 6 etc.; II: 15, 17 etc.
              </div>
              <div className="page-content-text">
                Motivarea absenţelor se face de învăţător/institutor/profesorul
                pentru învăţământul primar, prin încercuirea acestora cu
                cerneală albastră, pe baza documentelor prezentate de
                elevi/părinţi.
              </div>
              <div className="page-content-text">
                Absenţele puse elevilor care au întârziat la orele de curs din
                motive obiective se motivează la sfârşitul orei de către
                învăţător/institutor/profesorul pentru învăţământul primar.
              </div>
              <div className="page-content-text">
                Calificativele obţinute pe parcursul anului școlar se trec în
                catalog cu cerneală albastră, indicându-se ziua şi luna.
              </div>
              <div className="page-content-text">
                Toate calificativele se trec în catalog citeţ și corect.
              </div>
              <div className="page-content-text">
                Calificativele anuale pe discipline se trec cu cerneală roşie.
              </div>
              <div className="page-content-text">
                Calificativele încheiate la examenele de corigenţă constituie şi
                calificative anuale la disciplinele respective, consemându-se în
                rubrica "Calificativ anual".
              </div>
              <div className="page-content-text">
                Nu se admit modificări ale calificativelor în cataloagele
                şcolare. Calificativele înscrise greşit în catalog se anulează,
                barându-se cu o linie orizontală şi se înlocuiesc cu
                calificative corespunzătoare, înscrise cu cerneală roşie, sub
                semnătura învăţătorului/institutorului/ profesorului pentru
                învăţământul primar/profesorului şi a directorului şi cu
                aplicarea sigiliului.
              </div>
              <div className="page-content-text">
                Scoaterea catalogului din unitatea de învăţământ este interzisă.
              </div>
              <div className="page-content-text">
                Directorul este obligatsă controleze periodic completarea la zi
                a cataloagelor cu datele respective (absenţe, calificative,
                situaţii statistice etc.) şi să asigure, după încheierea anului
                şcolar, păstrarea lorîn arhiva şcolii, conform normelor în
                vigoare.
              </div>
            </div>
          </div>
          <div className="page-count">
            2/{5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
            Catalogul clasei {toRoman(parseInt(codClasa))}-a{' '}
            {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
          </div>
        </section>
        <section className="page third-page new-page" aria-label="third-page">
          <div className="content mt-12">
            <div className="header">{denumireMinister?.toUpperCase()} *</div>
            <div className="divider"></div>
            <div className="school-name-section">
              <div className="school-name">{schoolName}</div>
              <div className="sub-school-name-text">
                (unitatea de învățămant)
              </div>
            </div>
            <div className="location-section flex-start">
              <div className="city">
                {schoolLocalitate} {schoolJudet}
              </div>
              <div className="county">(Localitatea și județul)</div>
            </div>
            <div className="class-catalogue-section">
              <div className="class-catalogue-text">
                Catalogul Clasei a {toRoman(parseInt(codClasa))}-a{' '}
                {codClasa[codClasa.length - 1]} MAG
              </div>
              <div className="sub-class-catalogue-text">
                {getTypeOfInvatamant(true)}
              </div>
            </div>
            <div className="school-year">
              ANUL ȘCOLAR {anInceput}-{anSfarsit}
            </div>
            <div className="director-headmaster-section flex gap-24">
              <div className="director-section flex flex-col items-center">
                <div className="position-text">DIRECTOR,</div>
                <div className="position-name">{director}</div>
                <div className="position-text">L.S.</div>
              </div>
              <div className="headmaster-section flex flex-col items-center">
                <div className="position-text">{getHeadmasterTitle()},</div>
                <div className="position-name">{cadruDidacticResponsabil}</div>
              </div>
            </div>
            <div className="table-section w-full">
              <div className="table-title text-center mb-4">
                CADRE DIDACTICE
              </div>
              <div className="table-content">
                {cadreSiDisciplineChunks.map((chunk, chunkIndex) => (
                  <table key={chunkIndex}>
                    <thead>
                      <tr>
                        <th>DISCIPLINE</th>
                        <th>PROFESORUL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chunk.map((cadruDisciplina, index) => (
                        <tr key={index}>
                          <td>{cadruDisciplina.disciplina}</td>
                          <td>{cadruDisciplina.cadru}</td>
                        </tr>
                      ))}
                      {/* Add empty rows if this is the last chunk */}
                      {cadreSiDisciplineChunks.length !== 1 &&
                        chunkIndex === cadreSiDisciplineChunks.length - 1 &&
                        Array.from({
                          length: 12 - cadreSiDiscipline.slice(12).length,
                        }).map((_, index) => (
                          <tr key={index}>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ))}

                {/* If there's only one chunk, add an additional empty table */}
                {cadreSiDisciplineChunks.length === 1 && (
                  <table>
                    <thead>
                      <tr>
                        <th>DISCIPLINE</th>
                        <th>PROFESORUL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Empty table body */}
                      {Array.from({ length: 12 }).map((_, index) => (
                        <tr key={index}>
                          <td></td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="sub-table-text-section flex flex-col">
              <div className="text mb-4">
                *) Disciplinele / modulele de invatamant vor fi notate in
                ordinea inscrisa in Planul cadru aprobat prin ordin al
                ministruluivalabil pentru anul scolar in curs cu denumirile
                complete fara a se utiliza prescurtari sau abrevieri
              </div>
              <div className="divider"></div>
              <div className="text">* denumirea curentă a ministerului</div>
            </div>
          </div>
          <div className="page-count">
            3/{5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
            Catalogul clasei a {toRoman(parseInt(codClasa))}-a{' '}
            {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
          </div>
        </section>
        {studentsCatalogChunks.map((chunk, index) => {
          return (
            <section
              className="page fourth-page new-page"
              aria-label="fifth-page"
            >
              <div className="content mt-6">
                <div className="flex items-center flex-col fit-content">
                  <table className="tg">
                    <thead>
                      <tr>
                        <th className="tg-0pky" rowSpan={3}>
                          Elevii
                        </th>
                        <th className="tg-0pky" rowSpan={3}></th>
                        <th
                          className="tg-0pky"
                          colSpan={numberOfSubjects * 2 + 1}
                        >
                          Discipline / module de invatamant
                        </th>
                        <th className="tg-0pky" colSpan={2}>
                          Absente
                        </th>
                      </tr>
                      <tr>
                        {Object.keys(studentsCatalog[0].materiiInfo).map(
                          (_, i) => (
                            <React.Fragment key={i}>
                              <th className="tg-0pky" colSpan={2}>
                                {
                                  studentsCatalog[0].materiiInfo[_]
                                    .denumireMaterie
                                }
                              </th>
                            </React.Fragment>
                          )
                        )}
                        <th className="tg-0pky" rowSpan={2}>
                          <div>Consiliere</div>
                          <div>Purtare</div>
                        </th>
                        <th className="tg-0pky" rowSpan={2}>
                          Total
                        </th>
                        <th className="tg-0pky" rowSpan={2}>
                          <div>dintre care</div>
                          <div>nemotivate</div>
                        </th>
                      </tr>
                      <tr>
                        {Array.from({ length: numberOfSubjects * 2 }).map(
                          (_, i) => (
                            <th key={i} className="tg-0pky">
                              {i % 2 === 1 ? 'Calif.' : 'Abs.'}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {chunk.map((student, studentIndex) => {
                        return (
                          <React.Fragment key={studentIndex}>
                            <tr>
                              <td className="wide-cell" rowSpan={4}>
                                <div className="student-name ">
                                  {index * 2 + 1 + studentIndex}.{' '}
                                  {student.numeComplet.split(' ')[0]}{' '}
                                  {student.prenumeTata?.[0]}{' '}
                                </div>
                                <div className="student-name">
                                  {student.numeComplet
                                    .split(' ')
                                    .slice(1)
                                    .join(' ')}
                                </div>
                                <div className="small-text">Nr. matricol:</div>
                                <div className="small-text">
                                  Trecut în registrul
                                </div>
                                <div className="small-text">matricol vol.</div>
                                <div className="small-text"></div>
                                <div className="small-text mt-4">
                                  SITUAȚIA ȘCOLARĂ
                                </div>
                                <div className="small-text">
                                  La încheierea cursurilor
                                </div>
                                <div
                                  className={`small-text ${
                                    studentsCatalog[index * 2 + studentIndex]
                                      .situatie === 'Promovat'
                                      ? 'text-green-600'
                                      : 'text-red-600'
                                  }`}
                                >
                                  {studentsCatalog[
                                    index * 2 + studentIndex
                                  ].situatie?.toUpperCase()}
                                </div>
                                <div className="small-text">
                                  La finalul anului școlar
                                </div>
                                <div className="small-text">
                                  Media generală{' '}
                                  <span className="text-red-600">
                                    {
                                      studentsCatalog[index * 2 + studentIndex]
                                        .medieGenerala
                                    }
                                  </span>
                                </div>
                                <div className="small-text">
                                  Mentiuni{' '}
                                  <span className="align-text-top">4)</span>
                                </div>
                              </td>
                              {Array.from({
                                length: numberOfSubjects * 2 + 4,
                              }).map((_, colIndex) => {
                                if (colIndex === 0) {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="tg-0pky big-cell"
                                    ></td>
                                  );
                                } else if (
                                  colIndex > 0 &&
                                  colIndex < numberOfSubjects * 2 + 1
                                ) {
                                  const subjectIndex = Math.floor(
                                    (colIndex - 1) / 2
                                  );
                                  if (colIndex % 2 === 1) {
                                    return (
                                      <td
                                        key={colIndex}
                                        className="tg-0pky big-cell"
                                      >
                                        {fetchAbsCalif(
                                          index * 2 + studentIndex,
                                          subjectIndex,
                                          false
                                        )}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        key={colIndex}
                                        className="tg-0pky big-cell"
                                      >
                                        {fetchAbsCalif(
                                          index * 2 + studentIndex,
                                          subjectIndex,
                                          true
                                        )}
                                      </td>
                                    );
                                  }
                                } else if (
                                  colIndex ===
                                  numberOfSubjects * 2 + 1
                                ) {
                                  return <td></td>;
                                } else if (
                                  colIndex ===
                                  numberOfSubjects * 2 + 2
                                ) {
                                  // total absente
                                  return (
                                    <td className="tg-0pky">
                                      {
                                        studentsCatalog[
                                          index * 2 + studentIndex
                                        ].absente
                                      }
                                    </td>
                                  );
                                } else {
                                  // total nemotivate
                                  return (
                                    <td className="tg-0pky">
                                      {
                                        studentsCatalog[
                                          index * 2 + studentIndex
                                        ].absenteNemotivate
                                      }
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                            {Array.from({ length: 3 }).map((_, rowIndex) => (
                              <tr key={rowIndex}>
                                <td className="tg-0pky">
                                  {rowIndex === 0 ? (
                                    <div>
                                      {anStudiu < 5 ? 'Calificativ' : 'Medie'}
                                    </div>
                                  ) : rowIndex === 1 ? (
                                    <div>
                                      <div>Medie la ex.</div>
                                      <div>corig.</div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div>
                                        {anStudiu < 5 ? 'Calificativ' : 'Medie'}
                                      </div>
                                      <div>
                                        {anStudiu < 5 ? 'anual' : 'anuală'}
                                      </div>
                                    </div>
                                  )}
                                </td>
                                {Array.from({ length: numberOfSubjects }).map(
                                  (_, subjIndex) => (
                                    <React.Fragment key={subjIndex}>
                                      <td
                                        className="tg-0pky average-cell text-red-600"
                                        colSpan={2}
                                      >
                                        {fetchAverage(
                                          index * 2 + studentIndex,
                                          rowIndex,
                                          subjIndex
                                        )}
                                      </td>
                                    </React.Fragment>
                                  )
                                )}
                                {Array.from({ length: 3 }).map(
                                  (_, extraIndex) => {
                                    if (
                                      (rowIndex === 0 || rowIndex === 2) &&
                                      extraIndex === 0
                                    ) {
                                      return (
                                        <td
                                          key={extraIndex}
                                          className="tg-0pky"
                                        >
                                          {
                                            studentsCatalog[
                                              index * 2 + studentIndex
                                            ].mediePurtare
                                          }
                                        </td>
                                      );
                                    }
                                    if (
                                      rowIndex === 2 &&
                                      (extraIndex === 1 || extraIndex === 2)
                                    ) {
                                      return (
                                        <td
                                          key={extraIndex}
                                          className="tg-0pky"
                                        >
                                          0
                                        </td>
                                      );
                                    }
                                    return (
                                      <td
                                        key={extraIndex}
                                        className="tg-0pky"
                                      ></td>
                                    );
                                  }
                                )}
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="text-xs self-start">
                    *) Disciplinele / modulele de invatamant vor fi notate in
                    ordinea strict inscrisa in Planul cadru aprobat prin ordin
                    al ministrului valabil pentrul anul scolar in curs, cu
                    denumirile complete fara a se utiliza prescurtari sau
                    abrevieri.
                  </div>
                </div>
              </div>
              <div className="page-count">
                {4 + index}/
                {5 + studentsCatalogChunks.length + datePersonaleChunks.length}{' '}
                - Catalogul clasei {toRoman(parseInt(codClasa))}-a{' '}
                {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
              </div>
            </section>
          );
        })}
        <section className="page fifth-page new-page" aria-label="fifth-page">
          <div className="content mt-12">
            <div className="title text-center mb-8">
              Situația generală asupra mișcării și frecvenței elevilor și a
              rezultatelor obținute în timpul anului școlar
            </div>
            <div className="table-section mb-5">
              <table className="tg">
                <thead>
                  <tr>
                    <th className="tg-0lax" colSpan={3}>
                      INDICATORI
                    </th>
                    <th className="tg-0lax">ANUAL</th>
                    <th className="tg-0lax" colSpan={3}>
                      INDICATORI
                    </th>
                    <th className="tg-0lax">ANUAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan={16}>
                      <div className="text">NUMĂRUL</div>
                      <div className="text">ELEVILOR</div>
                    </td>
                    <td className="tg-0lax" colSpan={2}>
                      Înscriși la începutul anului școlar
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.inscrisi}
                    </td>
                    <td className="tg-0lax" rowSpan={16}>
                      <div className="text">NUMĂRUL</div>
                      <div className="text">ELEVILOR</div>
                    </td>
                    <td className="tg-0lax" rowSpan={6}>
                      Promovați
                    </td>
                    <td className="tg-0lax" rowSpan={6}>
                      la încheierea anului școlar
                    </td>
                    <td className="tg-0lax value-cell" rowSpan={6}>
                      {situatieElevi.promovati}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" colSpan={2}>
                      Veniţi în timpul anului şcolar
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.veniti}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" colSpan={2}>
                      Plecaţi în timpul anului şcolar
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.plecati}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" colSpan={2}>
                      Existenti la încheierea cursurilor
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.existenti}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" colSpan={2}>
                      Promovaţi
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.promovati}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" rowSpan={2}>
                      Corigenţi
                    </td>
                    <td className="tg-0lax">o disciplină/ un modul</td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.corigentix1}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">
                      <div>două discipline/ două</div>
                      <div>module</div>
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.corigentix2}
                    </td>
                    <td className="tg-0lax" rowSpan={10}>
                      Repetenţi
                    </td>
                    <td className="tg-0lax" rowSpan={2}>
                      <div>1) din cauza situaţiei la</div>
                      <div>învăţătură</div>
                    </td>
                    <td className="tg-0lax value-cell" rowSpan={2}>
                      {situatieElevi.repetenti.info[1]}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" colSpan={2}>
                      Repetenți
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.repetenti.count}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" rowSpan={6}>
                      Amânați
                    </td>
                    <td className="tg-0lax">
                      <div>o disciplină/</div>
                      <div>un modul</div>
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.amanatix1}
                    </td>
                    <td className="tg-0lax" rowSpan={2}>
                      <div>2) din cauza absenţelor</div>
                      <div>acumulate din următoarele</div>
                      <div>motive:</div>
                    </td>
                    <td className="tg-0lax value-cell" rowSpan={2}></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">
                      <div>mai multe discipline/</div>
                      <div>mai multe module</div>
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.amanatix2}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" rowSpan={2}>
                      <div className="mb-3">
                        <div className="text">Total</div>
                        <div className="text">din care:</div>
                      </div>
                      <div>- amânati medical</div>
                    </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.motive.count}
                    </td>
                    <td className="tg-0lax" rowSpan={2}>
                      • medicale
                    </td>
                    <td className="tg-0lax value-cell" rowSpan={2}>
                      {situatieElevi.repetenti.info[2]}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.motive.info[1]}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">- abandon şcolar </td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.motive.info[2]}
                    </td>
                    <td className="tg-0lax">• abandon scolar</td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.repetenti.info[3]}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">- alte situaţii</td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.amanati.motive.info[3]}
                    </td>
                    <td className="tg-0lax" rowSpan={3}>
                      3) alte situatii
                    </td>
                    <td className="tg-0lax value-cell" rowSpan={3}>
                      {situatieElevi.repetenti.info[4]}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax" rowSpan={2}>
                      Absenţe
                    </td>
                    <td className="tg-0lax">Total</td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.absenteTotal}
                    </td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">din care nemotivate</td>
                    <td className="tg-0lax value-cell">
                      {situatieElevi.absenteNemotivate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="director-headmaster-section flex justify-center gap-24">
              <div className="director-section">
                <div className="position-text text-center">Director,</div>
                <div className="name-text">{director}</div>
              </div>
              <div className="headmaster-section">
                <div className="position-text text-center">
                  {getHeadmasterTitle().toUpperCase()},
                </div>
                <div className="name-text">{cadruDidacticResponsabil}</div>
              </div>
            </div>
          </div>
          <div className="page-count">
            {4 + studentsCatalogChunks.length}/
            {5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
            Catalogul clasei {toRoman(parseInt(codClasa))}-a{' '}
            {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
          </div>
        </section>
        {datePersonaleChunks.map((chunk, chunkIndex) => (
          <section
            className="page sixth-page new-page"
            aria-label={`page-${chunkIndex + 1}`}
            key={chunkIndex}
          >
            <div className="content mt-12">
              <div className="title text-center mb-8">
                DATE PERSONALE ALE ELEVILOR
              </div>
              <div className="table-section mb-5">
                <table className="tg">
                  <thead>
                    <tr>
                      <th className="tg-0lax" rowSpan={2}>
                        Nr. Crt.
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Nr. Volum
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Nr. Matricol
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Nr. Pagina
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Numele și prenumele
                        <div>elevilor</div>
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        <div>Data nașterii</div>
                        <div>(ziua, luna, anul)</div>
                      </th>
                      <th className="tg-0lax" colSpan={2}>
                        <div>Prenumele</div>
                        <div>părinților</div>
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Domiciliul și numărul de telefon
                      </th>
                      <th className="tg-0lax" rowSpan={2}>
                        Mențiuni
                      </th>
                    </tr>
                    <tr>
                      <th className="tg-0lax">Tata</th>
                      <th className="tg-0lax">Mama</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chunk.map((student, index) => (
                      <tr key={index}>
                        <td className="tg-0lax">
                          {index + 1 + chunkIndex * 12}
                        </td>
                        <td className="tg-0lax">
                          {student.volumRegistruMatricol}
                        </td>
                        <td className="tg-0lax">{student.nrMatricol}</td>
                        <td className="tg-0lax">
                          {student.paginaRegistruMatricol}
                        </td>
                        <td className="tg-0lax">{student.numeComplet}</td>
                        <td className="tg-0lax">
                          {dayjs(student.dataNastere).format('DD.MM.YYYY')}
                        </td>
                        <td className="tg-0lax">{student.prenumeTata}</td>
                        <td className="tg-0lax">{student.prenumeMama}</td>
                        <td className="tg-0lax">
                          {student.domiciliuSiNumarTelefon}
                        </td>
                        <td className="tg-0lax">{student.mentiuni}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="director-headmaster-section flex justify-center gap-24">
                <div className="director-section">
                  <div className="position-text text-center">Director,</div>
                  <div className="name-text">{director}</div>
                </div>
                <div className="headmaster-section">
                  <div className="position-text text-center">
                    {getHeadmasterTitle()},
                  </div>
                  <div className="name-text">{cadruDidacticResponsabil}</div>
                </div>
              </div>
            </div>
            <div className="page-count">
              {5 + studentsCatalogChunks.length + chunkIndex}/
              {5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
              Catalogul clasei {toRoman(parseInt(codClasa))}-a{' '}
              {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
            </div>
          </section>
        ))}
        <section className="page seventh-page new-page" aria-label="fifth-page">
          <div className="content mt-12">
            <div className="title text-center">PROCES-VERBAL</div>
            <div className="main-text-section flex flex-col items-center">
              <div className="regular-text">
                Încheiat astăzi - {dayjs(reportDate).format('DD.MM.YYYY')}
              </div>
              <div>
                Subsemnatul, {cadruDidacticResponsabil} {getHeadmasterTitle()},
              </div>
              <div className="regular-text">
                menționez că, în data de{' '}
                {dayjs(reportDate).format('DD.MM.YYYY')}, am verificat
                încheierea situației școlare:{' '}
                {anStudiu < 5 ? 'calificativele' : 'mediile'} anuale
              </div>
              <div className="regular-text">
                (responsabilitatea pentru greșeli și corecturi revine cadrului
                didactic), am totalizat absențele
              </div>
              <div className="regular-text">
                și am completat statistica cu datele corespunzatoare.
              </div>
              <div className="regular-text">
                Predau Doamnei Director {director} prezentul catalog,
              </div>
              <div className="regular-text">
                care conține {studentsCatalog.length} poziții, din care:{' '}
                {studentsCatalog.length} completate, _______ necompletate,
                _______ anulate
              </div>
              <div className="director-headmaster-section flex gap-16 mt-3 ">
                <div className="director text-center">
                  <div className="text">Am predat,</div>
                  <div className="text">{getHeadmasterTitle()}</div>
                  <div className="text">{cadruDidacticResponsabil}</div>
                </div>
                <div className="headmaster text-center">
                  <div className="text">Am primit,</div>
                  <div className="text">Director</div>
                  <div className="text">{director}</div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="regular-text">
                Verificat și predat spre arhivare doamnei secretar șef/secretar{' '}
                {selectedSecretar}
              </div>
              <div className="director-secretary-section flex gap-16 mt-3 ">
                <div className="director text-center">
                  <div className="text">Am predat,</div>
                  <div className="text">Director</div>
                  <div className="text">{director}</div>
                  <div className="text">................................</div>
                </div>
                <div className="secretary text-center">
                  <div className="text">Am primit,</div>
                  <div className="text">Secretar șef/secretar</div>
                  <div className="text">{selectedSecretar}</div>
                  <div className="text">................................</div>
                </div>
              </div>
              <div className="school-name text-center">{schoolName}</div>
              <div className="sub-school-name text-center">
                (unitatea de învățămant)
              </div>
              <div className="title text-center">PRECIZARE</div>
              <div className="subtitle text-center">
                În atenția profesorilor care predau la clasa a{' '}
                {toRoman(parseInt(codClasa))}-a {codClasa[codClasa.length - 1]}{' '}
                MAG
              </div>
              <div className="subtitle text-center">
                Următorii profesori sunt responsabili de corectitudinea
                situației școlare a elevilor:
              </div>
            </div>
            <div className="table-section table-content w-full">
              {cadreSiDisciplineMediiChunks.map((chunk, chunkIndex) => {
                return (
                  <table>
                    <thead>
                      <tr>
                        <th>DISCIPLINE</th>
                        <th>PROFESORUL</th>
                        <th>SEMNĂTURĂ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chunk.map((cadruDisciplina, index) => (
                        <tr key={index}>
                          <td>{cadruDisciplina.disciplina}</td>
                          <td>{cadruDisciplina.cadru}</td>
                          <td></td>
                        </tr>
                      ))}
                      {chunkIndex === cadreSiDisciplineMediiChunks.length - 1 &&
                        Array.from({
                          length: 12 - cadreSiDiscipline.slice(12).length,
                        }).map((_, index) => (
                          <tr key={index}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                );
              })}
            </div>
            <div className="subtitle self-start mt-2">
              {getHeadmasterTitle()}
            </div>
            <div className="subtitle self-start">
              {cadruDidacticResponsabil}
            </div>
            <div className="subtitle self-start">(numele și prenumele)</div>
            <div className="subtitle self-start">
              Semnătura.............................
            </div>
          </div>
          <div className="page-count">
            {5 + studentsCatalogChunks.length + datePersonaleChunks.length}/
            {5 + studentsCatalogChunks.length + datePersonaleChunks.length} -
            Catalogul clasei {toRoman(parseInt(codClasa))}-a{' '}
            {codClasa[codClasa.length - 1]} MAG {anInceput}-{anSfarsit}
          </div>
        </section>
      </div>
    );
  }
}

export const PrintCatalogReport = () => {
  const componentRef = useRef(null);
  const [catalogReportInfo, setCatalogReportInfo] = useState({
    schoolName: '',
    schoolLocalitate: '',
    schoolJudet: '',
    codClasa: '',
    anInceput: '',
    anSfarsit: '',
    anStudiu: 0,
    director: '',
    cadruDidacticResponsabil: '',
    cadreSiDiscipline: [],
    denumireMinister: null,
    situatieElevi: {
      inscrisi: 0,
      veniti: 0,
      plecati: 0,
      existenti: 0,
      promovati: 0,
      corigentix1: 0,
      corigentix2: 0,
      repetenti: {
        count: 0,
        info: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
        },
      },
      amanati: {
        amanatix1: 0,
        amanatix2: 0,
        motive: {
          count: 0,
          info: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
          },
        },
      },
      absenteTotal: 0,
      absenteNemotivate: 0,
    },
    cadreSiDisciplineMedii: [],
    catalogInfo: null,
    reportDate: dayjs(),
    selectedSecretar: '',
    studentsCatalog: [],
  });

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === 'catalogReportJSONPrint') {
        const data = localStorage.getItem('catalogReportJSONPrint');
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    // Read data immediately in case it's already set
    const savedData = localStorage.getItem('catalogReportJSONPrint');
    if (savedData) {
      const initialData = JSON.parse(savedData);
      setCatalogReportInfo(initialData);
    }

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  return (
    <div className="relative">
      <div className="fixed top-12 right-12">
        <ReactToPrint
          trigger={() => (
            <button className="rounded text-xl font-bold bg-pink-500 p-3 text-white">
              SAVE PDF
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      {catalogReportInfo.studentsCatalog.length && (
        <CatalogReport
          ref={componentRef}
          catalogReportInfo={catalogReportInfo}
        />
      )}
    </div>
  );
};
