import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EditAction } from './EditAction';
import { TablesConfig } from '../TablesConfig';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';

export const ClassActions = ({
  id,
  path,
  isPrimaryGrade,
  codClasa,
}: {
  id: number;
  path: string;
  isPrimaryGrade: boolean;
  codClasa: string;
}) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { account } = userContext.state;
  console.log(codClasa);

  if (
    [UserRoles.ROLE_ELEV, UserRoles.ROLE_PARINTE].includes(
      account!.selectedView
    )
  ) {
    return <></>;
  }

  return (
    <div className="flex gap-2">
      {[
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_DIRIGINTE,
        UserRoles.ROLE_INVATATOR,
      ].includes(account!.selectedView) && (
        <Tooltip arrow title="Vezi elevi clasa">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(TablesConfig().userClassConfig.path, {
                state: {
                  queryParams: {
                    'clasa.id': id,
                  },
                  removeFilters: ['clasa.codClasa'],
                  onCreateNavigateState: {
                    clasaId: id,
                  },
                },
              });
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton color="secondary">
                <RemoveRedEyeIcon />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      )}
      {[
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_DIRIGINTE,
        UserRoles.ROLE_INVATATOR,
      ].includes(account!.selectedView) && (
        <Tooltip arrow title="Vezi catalog clasa">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(TablesConfig().catalogConfig.path, {
                state: {
                  queryParams: {
                    'clasa.id': id,
                  },
                  removeFilters: ['clasa.codClasa'],
                },
              });
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton color="secondary">
                <LocalLibraryIcon />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      )}
      {[
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_DIRIGINTE,
        UserRoles.ROLE_INVATATOR,
      ].includes(account!.selectedView) && (
        <Tooltip arrow title="Vezi medii clasa">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(
                // [
                //   isPrimaryGrade
                //     ? 'primaryAverageConfig'
                //     : 'nonPrimaryAverageConfig'
                // ].path,
                TablesConfig().averageConfig.path,
                {
                  state: {
                    routeSpecificId: id,
                    removeFilters: ['user.clasaActiva.clasa.codClasa'],
                  },
                }
              );
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton color="secondary">
                <LocalLibraryIcon />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      )}
      {[UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR].includes(
        account!.selectedView
      ) && (
        <Tooltip arrow title="Vezi corigente clasa">
          <div
            className="cursor-pointer"
            onClick={() => {
              console.log(codClasa);
              navigate('/corigenta', {
                state: {
                  classId: id,
                  isPrimaryGrade,
                  codClasa,
                },
              });
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton color="secondary">
                <LocalLibraryIcon />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      )}
      {[UserRoles.ROLE_SCHOOL_ADMIN, UserRoles.ROLE_SECRETAR].includes(
        account!.selectedView
      ) && <EditAction id={id} path={path} />}
    </div>
  );
};
