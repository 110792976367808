import { SchoolYearApi } from 'api/schoolYear.api';
import { TimeSlotApi } from 'api/timeslot.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { TimePickerInput } from 'common/components/inputs/TimePickerInput';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import { isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse } from 'library/types/Common';
import { SchoolYear } from 'library/types/SchoolYear';
import { TimeSlot, TimeSlotPayload } from 'library/types/TimeSlot';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const TimeSlotModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { createTimeSlot, getTimeSlot, editTimeSlot } = TimeSlotApi();
  const { getSchoolYear } = SchoolYearApi();

  const [availableSchoolYears, setAvailableSchoolYears] = useState<
    Array<SchoolYear>
  >([]);

  const [timeSlotData, setTimeslotData] = useState<{
    oraStart: Dayjs | null;
    oraSfarsit: Dayjs | null;
    anScolar: string;
  }>({
    oraStart: null,
    oraSfarsit: null,
    anScolar: '',
  });

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  useEffect(() => {
    (async () => {
      const schoolYears =
        (await getSchoolYear()) as CollectionApiResponse<SchoolYear>;

      setAvailableSchoolYears(schoolYears['hydra:member']);

      if (editId) {
        const timeSlot = (await getTimeSlot({
          id: editId,
        })) as TimeSlot;
        setTimeslotData({
          oraStart: dayjs(timeSlot.oraStart).utc(),
          oraSfarsit: dayjs(timeSlot.oraSfarsit).utc(),
          anScolar: timeSlot.anScolar['@id'],
        });
      }
    })();
  }, []);

  const isActionValid = () => {
    if (!timeSlotData) return false;

    if (!userContext.state.account?.user.scoala.id) return false;

    if (!isObjectValid(timeSlotData)) {
      toast.error(
        tModules('schoolAdmin.timeSlot.submitTimeSlotValidationError')
      );
      return false;
    }

    const { oraStart, oraSfarsit } = timeSlotData;

    if (oraStart!.valueOf() > oraSfarsit!.valueOf()) {
      toast.error(tModules('schoolAdmin.timeSlot.endTimeAfterStartTimeError'));
      return false;
    }
    return true;
  };

  const submitTimeSlotAction = async () => {
    if (!isActionValid()) return;

    const { oraStart, oraSfarsit, anScolar } = timeSlotData!;

    const payloadObject: TimeSlotPayload = {
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      oraStart,
      oraSfarsit,
      anScolar,
    };

    !editId
      ? await createTimeSlot(payloadObject)
      : await editTimeSlot(editId, payloadObject);

    navigate('/time-slots');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularSelect
            options={availableSchoolYears.map((e) => ({
              label: e.anInceput + '',
              value: e['@id'],
            }))}
            onChange={(e) => {
              setTimeslotData((prev) => ({
                ...prev,
                anScolar: availableSchoolYears.find((el) => el['@id'] === e)![
                  '@id'
                ],
              }));
            }}
            selectProps={{
              label: tModules('schoolAdmin.timeSlot.schoolYear'),
              value: timeSlotData.anScolar,
              fullWidth: true,
            }}
          />
          <TimePickerInput
            timePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.timeSlot.timeSlotStartTime'),
              value: timeSlotData.oraStart,
              onChange: (e) => {
                setTimeslotData((prev) => ({
                  ...prev,
                  oraStart: e,
                }));
              },
            }}
          />
          <TimePickerInput
            timePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.timeSlot.timeSlotEndTime'),
              value: timeSlotData.oraSfarsit,
              onChange: (e) => {
                setTimeslotData((prev) => ({
                  ...prev,
                  oraSfarsit: e,
                }));
              },
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitTimeSlotAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
