export enum AcademicRecordType {
  'I - VIII' = 'I - VIII',
  'IX - XII' = 'IX - XII',
}

export interface AcademicRecordPrintVersion {
  schoolName: string;
  cif: string;
  type: AcademicRecordType;
  fullName: string;
  cnp: string;
  dateOfBirth: string;
  birthAddress: string;
  nationality: string;
  fatherFullName: string;
  motherFullName: string;
  secretaryName: string;
  jsonInfo: AcademicRecordJSON;
  directorName: string;
}

export interface AcademicRecord extends AcademicRecordPrintVersion {
  mediiMaterii: Array<{
    numeMaterie: string;
    medii: {
      [anStudiu: string]: string;
    };
  }>;
  mediiGenerale: Array<{
    anMaterie: string;
    medie: string;
  }>;
}

export type AcademicRecordJSON = {
  [key in AcademicRecordType]: {
    schoolSituation: Array<SchoolSituationJSON>;
    gradeSituation: GradeSituationJSON;
    mentions: string;
  };
};

export interface SchoolSituationJSON {
  schoolName: string;
  volume: {
    noOfPages: string;
    matricolNumber: string;
    volumeNumber: string;
  };
  yearAndEducationFormat: string;
  schoolYear: string;
  observations: string;
}

export interface GradeSituationJSON {
  mediiMaterii: Array<MateriiEntry>;
  mediiGenerale: Array<MediiGeneraleEntry>;
}

export type MediiEntry = {
  [year: string]: string;
};

export type MateriiEntry = {
  numeMaterie: string;
  medii: MediiEntry;
};

export type MediiGeneraleEntry = {
  anMaterie: string;
  medie: string;
};
