import { Typography } from '@mui/material';
import { convertToRoman, numberOfSchoolYears } from 'library/helpers/helper';
import {
  AcademicRecordPrintVersion,
  AcademicRecordType,
} from 'library/types/AcademicRecords';
import { useEffect, useState } from 'react';
import './PrintAcademicRecord.css';

const cif = 29351603;
const cnp = 1950602080081;

export const PrintAcademicRecord = () => {
  const [printInfo, setPrintInfo] = useState<AcademicRecordPrintVersion>({
    schoolName: '',
    cif: '',
    type: AcademicRecordType['I - VIII'],
    fullName: '',
    cnp: '',
    dateOfBirth: '',
    birthAddress: '',
    jsonInfo: {
      'I - VIII': {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
      'IX - XII': {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
    },
    fatherFullName: '',
    motherFullName: '',
    nationality: '',
    secretaryName: '',
    directorName: '',
  });

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === 'academicRecordPrintInfo') {
        const data = localStorage.getItem('academicRecordPrintInfo');
        console.log(data);
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    // Read data immediately in case it's already set
    const initialData = localStorage.getItem('academicRecordPrintInfo');
    if (initialData) {
      console.log(JSON.parse(initialData));
      setPrintInfo(JSON.parse(initialData));
    }

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  // mock data
  // const schoolSituation: Array<SchoolSituationJSON> =
  //   generateSchoolRecordMockData(6);

  // const { mediiGenerale, mediiMaterii } = generateAcademicRecordsMockData();
  // const noStudyYears = 8;

  // const mentions =
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

  if (!printInfo.schoolName) {
    return <></>;
  }

  return (
    <div
      style={{ backgroundColor: 'white' }}
      className="flex flex-col px-36 py-28 gap-10 print:px-8 print:py-8 print:gap-4"
    >
      <section
        aria-label="header"
        className="flex flex-col w-full gap-3 items-center"
      >
        <Typography variant="h3">ROMÂNIA</Typography>
        <div className="flex flex-col items-center justify-center">
          <Typography
            classes={{
              h5: 'text-center',
            }}
            variant="h5"
          >
            {printInfo.schoolName || '-'}
          </Typography>
          <Typography variant="headerLight">
            ( Unitatea de invatamant )
          </Typography>
        </div>
        <div className="flex gap-8 items-center">
          <Typography variant="headerLight">Codul CIF</Typography>
          <div className="flex">
            {Array.from(printInfo.cif).map((digit, index) => (
              <div
                key={index}
                className={`border print:border-black
              ${index !== cif.toString().length - 1 ? 'border-r-0' : ''}
              px-2 p-1 border-black`}
              >
                {digit}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3 items-center">
          <Typography variant="h4">Foaie matricolă</Typography>
          <Typography variant="h4">pentru clasele {printInfo.type}</Typography>
        </div>
      </section>
      <section
        aria-label="student-data"
        className="flex flex-col w-full gap-5 items-center"
      >
        <div className="flex flex-col items-center w-full">
          <div className="flex border border-black p-2 items-center w-full">
            {printInfo.fullName || '-'}
          </div>
          <Typography variant="subtitle2">( numele și prenumele )</Typography>
        </div>
        <div className="flex gap-16 items-center">
          <Typography variant="headerLight">Cod Numeric Personal</Typography>
          <div className="flex">
            {Array.from(printInfo.cnp).map((digit, index) => (
              <div
                key={index}
                className={`border print:border-black
              ${index !== cnp.toString().length - 1 ? 'border-r-0' : ''}
              px-2 p-1 border-black`}
              >
                {digit}
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-3 w-full">
          <div className="flex flex-col items-center w-2/3">
            <div className="flex border border-black p-2 items-center w-full">
              {printInfo.dateOfBirth + ' - ' + printInfo.birthAddress}
            </div>
            <Typography variant="subtitle2">
              ( Data și locul nașterii )
            </Typography>
          </div>
          <div className="flex flex-col items-center w-1/3">
            <div className="flex border border-black p-2 items-center w-full">
              {printInfo.nationality || '-'}
            </div>
            <Typography variant="subtitle2">( Naționalitatea )</Typography>
          </div>
        </div>
        <div className="flex gap-2 border border-black p-2 w-full items-center">
          <Typography variant="bodyMedium">Numele și prenumele</Typography>
          <div className="flex flex-col">
            <Typography variant="bodyMedium">tatălui</Typography>
            <Typography variant="bodyMedium">mamei</Typography>
          </div>
          <div className="flex flex-col flex-1">
            <div className="w-full border-b-2 border-black">
              <Typography variant="bodySemiBold">
                {printInfo.fatherFullName || '-'}
              </Typography>
            </div>
            <Typography variant="bodySemiBold">
              {printInfo.motherFullName || '-'}
            </Typography>
          </div>
        </div>
      </section>
      <section
        className="flex flex-col page-break"
        aria-label="academic-records"
      >
        <div className="ml-5 mb-3">
          <Typography variant="headerLight">
            a) Situatia Scolara generala
          </Typography>
        </div>
        <table className="min-w-full table-auto overflow-hidden shadow-lg">
          <thead className="text-black border-black border">
            <tr>
              <th
                rowSpan={2}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border"
              >
                School Name
              </th>
              <th
                colSpan={3}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider text-center border-black border"
              >
                Volum Matricol
              </th>
              <th
                rowSpan={2}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border"
              >
                Year and Education Format
              </th>
              <th
                rowSpan={2}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border"
              >
                School Year
              </th>
              <th
                rowSpan={2}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border"
              >
                Observations
              </th>
            </tr>
            <tr>
              <th className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border">
                No of Pages
              </th>
              <th className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border">
                Matricol Number
              </th>
              <th className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border">
                Volume Number
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-black">
            {printInfo.jsonInfo[
              AcademicRecordType[printInfo.type as AcademicRecordType]
            ].schoolSituation.map((item, index) => (
              <tr key={index}>
                <td className="p-2 whitespace-nowrap text-sm font-medium text-black border-black border">
                  {item.schoolName}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.volume.noOfPages}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.volume.matricolNumber}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.volume.volumeNumber}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.yearAndEducationFormat}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.schoolYear}
                </td>
                <td className="p-2 whitespace-nowrap text-sm text-black border-black border">
                  {item.observations}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="ml-7">
          <Typography variant="bodyRegular">
            * denumirea curenta a ministerului
          </Typography>
        </div>
      </section>
      <section className="flex flex-col page-break" aria-label="grade-records">
        <div className="ml-5 mb-3">
          <Typography variant="headerLight">
            b) Situatia scoalara pe discipline de invatamant si ani de studii
          </Typography>
        </div>
        <table className="min-w-full table-auto overflow-hidden shadow-lg">
          <thead className="text-black border-black border">
            <tr>
              <th
                rowSpan={2}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider text-center border-black border"
              >
                Disciplinele de invatamant
              </th>
              <th
                colSpan={numberOfSchoolYears(printInfo.type) + 1}
                className="p-2 text-center text-xs font-medium uppercase tracking-wider text-center border-black border"
              >
                Media pe ani de studii
              </th>
            </tr>
            <tr>
              {Array.from(
                { length: numberOfSchoolYears(printInfo.type) },
                (_, i) => i + 1
              ).map((item, index) => (
                <th
                  key={index}
                  className="p-2 text-center text-xs font-medium uppercase tracking-wider border-black border"
                >
                  {convertToRoman(item)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-black">
            {printInfo.jsonInfo[
              AcademicRecordType[printInfo.type as AcademicRecordType]
            ].gradeSituation.mediiMaterii.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td className="p-2 whitespace-nowrap text-sm font-medium text-black border-black border">
                  {item.numeMaterie}
                </td>
                {Array.from(
                  {
                    length: numberOfSchoolYears(printInfo.type),
                  },
                  (_, colIndex) => colIndex + 1
                ).map((gradesItem) => (
                  <td
                    key={`grade-${rowIndex}-${gradesItem}`}
                    className="p-2 whitespace-nowrap text-sm text-black border-black border"
                  >
                    {item.medii[gradesItem.toString()] || ''}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className=" p-2 border-black border">
                Media generala anuala
              </td>
              {Array.from(
                {
                  length: numberOfSchoolYears(printInfo.type),
                },
                (_, colIndex) => colIndex + 1
              ).map((gradesItem) => (
                <td
                  className="p-2 whitespace-nowrap text-sm text-black border-black border"
                  key={`general-grade-${gradesItem}`}
                >
                  {printInfo.jsonInfo[
                    AcademicRecordType[printInfo.type as AcademicRecordType]
                  ].gradeSituation.mediiGenerale.find(
                    (item) => parseInt(item.anMaterie) === gradesItem
                  )?.medie || ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </section>
      <section aria-label="mentions-footer" className="page-break">
        <div className="ml-5 mb-3">
          <Typography variant="headerLight">
            c) Mentiuni privind performantele obtinute, anii de repetentie,
            amanare medicala, respingere la examenul de absolvire etc.
          </Typography>
        </div>
        <div className="flex flex-col">
          <div className="px-5 py-5 border border-black">
            {
              printInfo.jsonInfo[
                AcademicRecordType[printInfo.type as AcademicRecordType]
              ].mentions
            }
          </div>
          <div className="flex flex-col gap-2 px-5 py-5 border border-t-0 border-black">
            <Typography variant="bodyRegular">
              Se certifica exactitatea datelor din prezenta foaie matricola
            </Typography>
            <div className="flex w-full mb-8">
              <div className="w-1/2">
                <Typography variant="bodyRegular">DIRECTOR</Typography>
              </div>
              <Typography variant="bodyRegular">SECRETAR</Typography>
            </div>
            <div className="mb-16">
              <Typography variant="bodyRegular">L.S.</Typography>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
