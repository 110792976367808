import mockProfilePic from './mock-avatar.jpg';

import logoTop from './logoTop.svg';
import logoJos from './logoJos.svg';

import fullCircle from './full-circle.svg';
import fullCircleFilled from './full-circle-filled.svg';

import penIcon from './pen.svg';
import greenMark from './green-mark.svg';
import redMark from './red-mark.svg';
import bookWithPencil from './book-with-pencil.svg';
import download from './download.svg';
import whiteRightArrow from './white-right-arrow.svg';

import blueBackground from './backgrounds/blue.jpg';
import blueBackgroundVector from './backgrounds/blue-vector.jpg';
import lightblueBackground from './backgrounds/lightblue.jpg';
import lightblueBackgroundVector from './backgrounds/lightblue-vector.jpg';
import purpleBackground from './backgrounds/purple.jpg';
import purpleBackgroundVector from './backgrounds/purple-vector.jpg';
import orangeBackground from './backgrounds/orange.jpg';
import orangeBackgroundVector from './backgrounds/orange-vector.jpg';

import rightArrowBlue from './themes/blue/right-arrow.svg';
import downArrowBlue from './themes/blue/down-arrow.svg';
import catalogBlue from './themes/blue/catalog.svg';
import iesireBlue from './themes/blue/iesire.svg';
import acasaBlue from './themes/blue/acasa.svg';
import evaluariBlue from './themes/blue/evaluari.svg';
import mesajeBlue from './themes/blue/mesaje.svg';
import lectiiBlue from './themes/blue/lectii.svg';
import orarBlue from './themes/blue/orar.svg';
import setariBlue from './themes/blue/setari.svg';
import temeBlue from './themes/blue/teme.svg';
import testeBlue from './themes/blue/teste.svg';
import profileBlue from './themes/blue/profile.svg';
import classesBlue from './themes/blue/class.svg';

import rightArrowPurple from './themes/purple/right-arrow.svg';
import downArrowPurple from './themes/purple/down-arrow.svg';
import catalogPurple from './themes/purple/catalog.svg';
import iesirePurple from './themes/purple/iesire.svg';
import acasaPurple from './themes/purple/acasa.svg';
import evaluariPurple from './themes/purple/evaluari.svg';
import mesajePurple from './themes/purple/mesaje.svg';
import lectiiPurple from './themes/purple/lectii.svg';
import orarPurple from './themes/purple/orar.svg';
import setariPurple from './themes/purple/setari.svg';
import temePurple from './themes/purple/teme.svg';
import testePurple from './themes/purple/teste.svg';
import profilePurple from './themes/purple/profile.svg';
import classesPurple from './themes/purple/class.svg';

import rightArrowOrange from './themes/orange/right-arrow.svg';
import downArrowOrange from './themes/orange/down-arrow.svg';
import catalogOrange from './themes/orange/catalog.svg';
import iesireOrange from './themes/orange/iesire.svg';
import acasaOrange from './themes/orange/acasa.svg';
import evaluariOrange from './themes/orange/evaluari.svg';
import mesajeOrange from './themes/orange/mesaje.svg';
import lectiiOrange from './themes/orange/lectii.svg';
import orarOrange from './themes/orange/orar.svg';
import setariOrange from './themes/orange/setari.svg';
import temeOrange from './themes/orange/teme.svg';
import testeOrange from './themes/orange/teste.svg';
import profileOrange from './themes/orange/profile.svg';
import classesOrange from './themes/orange/class.svg';

import rightArrowLightblue from './themes/lightblue/right-arrow.svg';
import downArrowLightblue from './themes/lightblue/down-arrow.svg';
import catalogLightblue from './themes/lightblue/catalog.svg';
import iesireLightblue from './themes/lightblue/iesire.svg';
import acasaLightblue from './themes/lightblue/acasa.svg';
import evaluariLightblue from './themes/lightblue/evaluari.svg';
import mesajeLightblue from './themes/lightblue/mesaje.svg';
import lectiiLightblue from './themes/lightblue/lectii.svg';
import orarLightblue from './themes/lightblue/orar.svg';
import setariLightblue from './themes/lightblue/setari.svg';
import temeLightblue from './themes/lightblue/teme.svg';
import testeLightblue from './themes/lightblue/teste.svg';
import profileLightblue from './themes/lightblue/profile.svg';
import classesLightblue from './themes/lightblue/class.svg';
import { Theme } from 'context/UserContext';

import ro from './flags/ro.png';
import en from './flags/gb.png';

export const icons = {
  general: {
    mockProfilePic,
    greenMark,
    redMark,
    logoTop,
    logoJos,
    fullCircle,
    fullCircleFilled,
    penIcon,
    bookWithPencil,
    download,
    whiteRightArrow,
  },
  flags: {
    ro,
    en,
  },
  themes: {
    [Theme.blue]: {
      background: blueBackground,
      backgroundVector: blueBackgroundVector,
      rightArrow: rightArrowBlue,
      downArrow: downArrowBlue,
      catalog: catalogBlue,
      iesire: iesireBlue,
      acasa: acasaBlue,
      evaluari: evaluariBlue,
      mesaje: mesajeBlue,
      lectii: lectiiBlue,
      orar: orarBlue,
      setari: setariBlue,
      teme: temeBlue,
      teste: testeBlue,
      profile: profileBlue,
      clase: classesBlue,
    },
    [Theme.lightblue]: {
      background: lightblueBackground,
      backgroundVector: lightblueBackgroundVector,
      rightArrow: rightArrowLightblue,
      downArrow: downArrowLightblue,
      catalog: catalogLightblue,
      iesire: iesireLightblue,
      acasa: acasaLightblue,
      evaluari: evaluariLightblue,
      mesaje: mesajeLightblue,
      lectii: lectiiLightblue,
      orar: orarLightblue,
      setari: setariLightblue,
      teme: temeLightblue,
      teste: testeLightblue,
      profile: profileLightblue,
      clase: classesLightblue,
    },
    [Theme.orange]: {
      background: orangeBackground,
      backgroundVector: orangeBackgroundVector,
      rightArrow: rightArrowOrange,
      downArrow: downArrowOrange,
      catalog: catalogOrange,
      iesire: iesireOrange,
      acasa: acasaOrange,
      evaluari: evaluariOrange,
      mesaje: mesajeOrange,
      lectii: lectiiOrange,
      orar: orarOrange,
      setari: setariOrange,
      teme: temeOrange,
      teste: testeOrange,
      profile: profileOrange,
      clase: classesOrange,
    },
    [Theme.purple]: {
      background: purpleBackground,
      backgroundVector: purpleBackgroundVector,
      rightArrow: rightArrowPurple,
      downArrow: downArrowPurple,
      catalog: catalogPurple,
      iesire: iesirePurple,
      acasa: acasaPurple,
      evaluari: evaluariPurple,
      mesaje: mesajePurple,
      lectii: lectiiPurple,
      orar: orarPurple,
      setari: setariPurple,
      teme: temePurple,
      teste: testePurple,
      profile: profilePurple,
      clase: classesPurple,
    },
  },
};
