import { CommonApi } from 'api/common.api';
import { UserApi } from 'api/user.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import {
  UtilityContext,
  UtilityContextActionTypes,
} from 'context/UtilityContext';
import dayjs from 'dayjs';
import { getStringValuesOfEnum, isObjectValid } from 'library/helpers/helper';
import {
  GenericResources,
  PageViewTypes,
  RequiredResources,
} from 'library/types/Common';
import {
  User,
  UserModuleData,
  UserPayload,
  UserRoles,
  rolesOptions,
} from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();
  const utilityContext = useContext(UtilityContext);
  const { pageViewType } = params;
  const location = useLocation();
  if (location && location.state) {
    var { editId: _id } = location?.state;
  }
  const isView = pageViewType === PageViewTypes.View;

  const { getUser, createUser, editUser, resetPassword } = UserApi();

  const { getResources } = CommonApi();

  const userContext = useContext(UserContext);
  const [userData, setUserData] = useState<UserModuleData>({
    tara: null,
    judet: null,
    localitate: null,
    email: '',
    roles: [],
    telefon: '',
    nume: '',
    prenume: '',
    initialaParinte: '',
    dataNastere: null,
    cnp: '',
    nrMatricol: '',
    adresa: '',
    mentiuniMedicale: '',
    cnpMama: '',
    cnpTata: '',
  });

  const [newPassword, setNewPassword] = useState<string>('');
  const [resetPasswordUsed, setResetPasswordUsed] = useState<boolean>(false);

  const [autocompleteInputValues, setAutocompleteInputValues] = useState<{
    localitate: string;
    judet: string;
    tara: string;
  }>({
    localitate: '',
    judet: '',
    tara: '',
  });

  const [userResources, setUserResources] = useState<
    Partial<GenericResources> | undefined
  >();

  const getUserResourcesAction = async () => {
    const userResources = await getResources({
      [RequiredResources.Tari]: 0,
    });
    setUserResources(userResources);
  };

  const getIndividualUser = async () => {
    if (pageViewType === 'create' || !_id || isNaN(parseInt(_id))) return;
    try {
      var user = (await getUser({ id: parseInt(_id) })) as User;
    } catch (e) {
      return navigate('/home');
    }
    const {
      tara,
      judet,
      localitate,
      email,
      roles,
      codUtilizator,
      telefon,
      nume,
      prenume,
      initialaParinte,
      dataNastere,
      cnp,
      nrMatricol,
      adresa,
      mentiuniMedicale,
      cnpMama,
      cnpTata,
    } = user;

    setUserData({
      tara,
      judet,
      localitate,
      email,
      roles,
      codUtilizator,
      telefon,
      nume,
      prenume,
      initialaParinte,
      dataNastere,
      cnp,
      nrMatricol,
      adresa,
      mentiuniMedicale,
      cnpMama,
      cnpTata,
    } as UserModuleData);
  };

  const loadIndividualUserPage = async () => {
    await getUserResourcesAction();
    await getIndividualUser();
    utilityContext.dispatch({
      type: UtilityContextActionTypes.ApiLoaded,
    });
  };

  useEffect(() => {
    if (!getStringValuesOfEnum(PageViewTypes).includes(pageViewType)) {
      navigate(`/user/view/${_id}`);
    }
  }, []);

  useEffect(() => {
    if (!userContext.state.account) return;
    loadIndividualUserPage();
  }, []);

  const isActionValid = () => {
    if (isView || (pageViewType !== 'create' && !_id) || !userData)
      return false;

    const adjustedUserData = { ...userData };

    if (
      !isObjectValid(userData, [
        'nrMatricol',
        'cnpMama',
        'cnpTata',
        'mentiuniMedicale',
        'telefon',
      ])
    ) {
      toast.error('All fields must be filled!');
      return false;
    }

    return true;
  };

  const userAction = async (id?: number) => {
    if (!isActionValid()) return;

    const {
      tara,
      judet,
      localitate,
      email,
      roles,
      telefon,
      nume,
      prenume,
      initialaParinte,
      dataNastere,
      cnp,
      nrMatricol,
      adresa,
      mentiuniMedicale,
      cnpMama,
      cnpTata,
    } = userData;

    const objectPayload: UserPayload = {
      localitate: `/api/localitati/${localitate!.id}`,
      judet: `/api/judete/${judet!.id}`,
      tara: `/api/tari/${tara!.id}`,
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      email,
      roles: roles.includes(UserRoles.ROLE_USER)
        ? roles
        : [...roles, UserRoles.ROLE_USER],
      telefon,
      nume,
      prenume,
      initialaParinte,
      dataNastere: dataNastere!,
      cnp,
      nrMatricol,
      adresa,
      mentiuniMedicale,
      cnpMama,
      cnpTata,
      status: '1',
      activ: true,
    };

    if (id) {
      await editUser(id, objectPayload);
    } else {
      await createUser(objectPayload);
    }

    navigate('/users');
  };

  const resetUserPassword = async () => {
    if (!_id) return;

    const response = await resetPassword(parseInt(_id));

    setNewPassword(response.password);
  };

  return (
    <div className="page-container">
      {userData && userResources && (
        <div className="flex items-center flex-col">
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            <div className="flex flex-col items-end">
              {_id && (
                <ButtonComp
                  onButtonClick={() => resetUserPassword()}
                  buttonText={tCommon('inputs.resetPasswordButton')}
                  buttonProps={{
                    variant: 'contained',
                    disabled: resetPasswordUsed,
                  }}
                  tailwindContainerClasses="flex justify-center"
                />
              )}
              {newPassword && (
                <div className="flex flex-col items-center">
                  <span className="text-xl">
                    {tModules('schoolAdmin.user.newPassword')}: {newPassword}
                  </span>
                </div>
              )}
            </div>
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.userRoles'),
                  value: userData.roles
                    .filter((e) => e !== UserRoles.ROLE_USER)
                    .map((e: any) => {
                      return rolesOptions.find((el: any) => {
                        return el.value === e;
                      })!.label;
                    })
                    .join(', '),
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={
                  userData.roles.includes(UserRoles.ROLE_ELEV)
                    ? [
                        rolesOptions.find(
                          (e) => e.value === UserRoles.ROLE_ELEV
                        )!,
                      ]
                    : userData.roles.length
                    ? [
                        ...rolesOptions.filter(
                          (e) => e.value !== UserRoles.ROLE_ELEV
                        ),
                      ]
                    : rolesOptions
                }
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    roles: typeof e === 'string' ? e.split(',') : e,
                  }));
                }}
                selectValues={userData.roles}
                selectProps={{
                  disabled: isView,
                  label: tModules('schoolAdmin.user.userRoles'),
                  multiple: true,
                  value: userData.roles,
                  renderValue: (selected: any) => {
                    const userRoleFiltered = selected.filter(
                      (e: any) => e !== 'ROLE_USER'
                    );
                    return userRoleFiltered
                      .map(
                        (e: any) =>
                          rolesOptions.find((el: any) => el.value === e)!.label
                      )
                      .join(', ');
                  },
                }}
              />
            )}
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.surname'),
                value: userData.nume,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      nume: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.firstName'),
                value: userData.prenume,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      prenume: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.address'),
                value: userData.adresa,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      adresa: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.email'),
                value: userData.email,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      email: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              isOptional={true}
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.phoneNumber'),
                value: userData.telefon,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      telefon: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.fatherInitial'),
                value: userData.initialaParinte,
                disabled: isView,
                onChange: (e) => {
                  const lastChar = e.target.value.slice(-1); // get the last character typed

                  if (lastChar.match(/[a-zA-Z]/)) {
                    // check if the last character is a letter
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        initialaParinte: lastChar.toUpperCase(),
                      };
                    });
                  }
                },
              }}
            />
            {userData.roles.includes(UserRoles.ROLE_ELEV) && (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.schoolRegistrationNumber'),
                  value: userData.nrMatricol,
                  disabled: isView,
                  onChange: (e) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        nrMatricol: e.target.value,
                      };
                    });
                  },
                }}
              />
            )}
            <RegularInput
              isOptional={true}
              textFieldProps={{
                fullWidth: true,
                rows: 5,
                multiline: true,
                label: tModules('schoolAdmin.user.medicalNotes'),
                value: userData.mentiuniMedicale,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      mentiuniMedicale: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.user.socialIdentifier'),
                value: userData.cnp,
                disabled: isView,
                onChange: (e) => {
                  setUserData((prevValue) => {
                    return {
                      ...prevValue,
                      cnp: e.target.value,
                    };
                  });
                },
              }}
            />
            {userData.roles.includes(UserRoles.ROLE_ELEV) && (
              <RegularInput
                isOptional={true}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.fatherSocialIdentifier'),
                  value: userData.cnpTata,
                  disabled: isView,
                  onChange: (e) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        cnpTata: e.target.value,
                      };
                    });
                  },
                }}
              />
            )}
            {userData.roles.includes(UserRoles.ROLE_ELEV) && (
              <RegularInput
                isOptional={true}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.motherSocialIdentifier'),
                  value: userData.cnpMama,
                  disabled: isView,
                  onChange: (e) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        cnpMama: e.target.value,
                      };
                    });
                  },
                }}
              />
            )}
            <DatePickerInput
              datePickerProps={{
                maxDate: dayjs().subtract(5, 'years'),
                slotProps: {
                  textField: { fullWidth: true },
                },
                disabled: isView,
                label: tModules('schoolAdmin.user.birthdayDate'),
                value: userData.dataNastere
                  ? dayjs(userData.dataNastere)
                  : null,
                onChange: (e) => {
                  setUserData((prev) => ({
                    ...prev,
                    dataNastere: e,
                  }));
                },
              }}
            />
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.country'),
                  value: userData.tara?.nume,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: isView,
                  options: userResources.tari!,
                  value: userData.tara,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        tara: newValue,
                        judet: null,
                        localitate: null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.tara,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        tara: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.country'),
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.county'),
                  value: userData.judet?.nume,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: !userData.tara || isView,
                  options: userData.tara
                    ? userResources.tari!.find(
                        (e) => e.id === userData.tara!.id
                      )!.judete
                    : [],
                  value: userData.judet,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        judet: newValue,
                        localitate: null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.judet,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        judet: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.county'),
                }}
              />
            )}

            {isView && (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.primaryCycle'),
                  value: userData.localitate?.nume,
                  disabled: true,
                }}
              />
            )}
            {!isView && (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: !userData.tara || !userData.judet || isView,
                  options: userData.judet
                    ? userResources
                        .tari!.find((e) => e.id === userData.tara!.id)!
                        .judete.find((e) => e.id === userData.judet!.id)!
                        .localitati
                    : [],
                  value: userData.localitate,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setUserData((prevValue) => {
                      return {
                        ...prevValue,
                        localitate: newValue,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.localitate,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        localitate: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.user.city'),
                }}
              />
            )}
          </div>
          {!isView && (
            <ButtonComp
              onButtonClick={() => {
                if (
                  !getStringValuesOfEnum(PageViewTypes).includes(
                    pageViewType
                  ) ||
                  (pageViewType !== 'create' && !_id)
                )
                  return;
                pageViewType === 'create'
                  ? userAction()
                  : userAction(parseInt(_id!));
              }}
              buttonText={tCommon('inputs.saveButton')}
              buttonProps={{
                variant: 'contained',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )}
        </div>
      )}
    </div>
  );
};
