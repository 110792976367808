import TextField, { TextFieldProps } from '@mui/material/TextField';

interface RegularInputProps {
  textFieldProps?: Partial<TextFieldProps>;
  tailwindContainerClasses?: string;
  isOptional?: boolean;
  noOptionalOrMandatory?: boolean;
}

export const RegularInput = (props: RegularInputProps): JSX.Element => {
  const {
    textFieldProps,
    tailwindContainerClasses,
    isOptional,
    noOptionalOrMandatory,
  } = props;
  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <TextField
        {...textFieldProps}
        label={
          textFieldProps?.label
            ? textFieldProps.label +
              (!noOptionalOrMandatory ? (isOptional ? '(optional)' : '*') : '')
            : ''
        }
        sx={{
          '& .Mui-disabled': {
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
            '&.MuiInputBase-input': {
              backgroundColor: 'rgba(0,0,0,0.05)',
            },
          },
        }}
      ></TextField>
    </div>
  );
};
