import { IRI, IriEntity } from './Common';
import { Catedra, School } from './School';
import { StudyYear } from './StudyYear';
import { User } from './User';

export interface SubjectType extends IriEntity {
  id: number;
  anStudiu: StudyYear;
  scoala: IRI;
  tipMaterie: string;
  catedra: Catedra;
  categorieMaterie: SubjectTypeCategory;
}

export interface SubjectTypeData {
  tipMaterie: string;
  anStudiu: StudyYear | null;
  catedra: IRI;
  categorieMaterie: SubjectTypeCategory;
}

export interface SubjectTypePayload {
  anStudiu: IRI;
  scoala: IRI;
  tipMaterie: string;
  catedra: IRI;
  categorieMaterie: SubjectTypeCategory;
}

export interface SubjectConfig extends IriEntity {
  id: number;
  tipMaterie: SubjectType | string;
  user: User;
  scoala: string;
  teza: boolean;
  procentTeza: number;
}

export interface SubjectConfigPayload {
  tipMaterie: string;
  user: string;
  scoala: string;
  teza: boolean;
  procentTeza: number;
}

export type SubjectTypeCollection = {
  id: number;
  scoala: School;
  anStudiu: StudyYear;
  tipMaterie: string;
  catedra: Catedra;
  categorieMaterie: SubjectTypeCategory;
};

export type SubjectConfigCollection = {
  id: number;
  user: User;
  tipMaterie: SubjectType;
  scoala: School;
  teza: boolean;
  procentTeza: number;
};

export interface SimpleSubjectConfig {
  ['@id']: IRI;
  id: number;
  user: string;
  tipMaterie: string;
}

export enum SubjectTypeCategory {
  TC = 'TC',
  CD = 'CD',
  CDL = 'CDL',
}
