import { SchoolApi } from 'api/school.api';
import { StudyYearApi } from 'api/studyYear.api';
import { SubjectApi } from 'api/subject.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { getStringValuesOfEnum, isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse } from 'library/types/Common';
import { Catedra } from 'library/types/School';
import { StudyYear } from 'library/types/StudyYear';
import {
  SubjectType,
  SubjectTypeCategory,
  SubjectTypeData,
  SubjectTypePayload,
} from 'library/types/Subject';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SubjectTypeModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { getStudyYear } = StudyYearApi();
  const { createSubjectType, getSubjectType, editSubjectType } = SubjectApi();
  const { getCatedra } = SchoolApi();

  const [subjectTypeData, setSubjectTypeData] = useState<SubjectTypeData>({
    anStudiu: null,
    tipMaterie: '',
    catedra: '',
    categorieMaterie: SubjectTypeCategory.CD,
  });

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  const [studyYearOptions, setStudyYearOptions] = useState<Array<StudyYear>>(
    []
  );
  const [catedraOptions, setCatedraOptions] = useState<Array<Catedra>>([]);

  useEffect(() => {
    (async () => {
      const studyYears =
        (await getStudyYear()) as CollectionApiResponse<StudyYear>;
      setStudyYearOptions(studyYears['hydra:member']);

      const catedre = (await getCatedra()) as CollectionApiResponse<Catedra>;
      setCatedraOptions(catedre['hydra:member']);
      if (editId) {
        const subjectType = (await getSubjectType({
          id: editId,
        })) as SubjectType;
        setSubjectTypeData({
          anStudiu: subjectType.anStudiu,
          tipMaterie: subjectType.tipMaterie,
          catedra: subjectType.catedra?.id.toString(),
          categorieMaterie: subjectType.categorieMaterie,
        });
      }
    })();
  }, []);

  const isActionValid = () => {
    if (!subjectTypeData) return false;

    if (!userContext.state.account?.user.scoala.id) return false;

    if (!isObjectValid(subjectTypeData)) {
      toast.error(
        tModules('schoolAdmin.subject.submitSubjectTypeValidationError')
      );
      return false;
    }
    return true;
  };

  const subjectTypeAction = async (id?: number) => {
    if (!isActionValid()) return;

    const { tipMaterie, anStudiu, catedra, categorieMaterie } =
      subjectTypeData!;

    const payloadObject: SubjectTypePayload = {
      anStudiu: `/api/aniStudiu/${anStudiu?.id}`,
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      tipMaterie,
      catedra: `/api/catedre/${catedra}`,
      categorieMaterie,
    };
    editId
      ? await editSubjectType(editId, payloadObject)
      : await createSubjectType(payloadObject);
    navigate('/subject-types');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.subject.subjectType'),
              value: subjectTypeData?.tipMaterie,
              onChange: (e) => {
                setSubjectTypeData((prev) => ({
                  ...prev,
                  tipMaterie: e.target.value,
                }));
              },
            }}
          />
          <RegularSelect
            options={catedraOptions!.map((e) => ({
              label: e.denumireCatedra,
              value: e.id,
            }))}
            onChange={(e) => {
              setSubjectTypeData((prev) => ({
                ...prev,
                catedra: e,
              }));
            }}
            selectProps={{
              label: tModules('schoolAdmin.subject.department'),
              value: subjectTypeData.catedra ?? '',
            }}
          />
          <RegularSelect
            options={getStringValuesOfEnum(SubjectTypeCategory)!.map((e) => ({
              label: e as SubjectTypeCategory,
              value: e as SubjectTypeCategory,
            }))}
            onChange={(e) => {
              setSubjectTypeData((prev) => ({
                ...prev,
                categorieMaterie: e,
              }));
            }}
            selectProps={{
              label: tModules('schoolAdmin.subject.subjectCategory'),
              value: subjectTypeData.categorieMaterie,
            }}
          />
          <RegularSelect
            options={studyYearOptions!.map((e) => ({
              label: e.anStudiu.toString(),
              value: e.id,
            }))}
            onChange={(e) => {
              setSubjectTypeData((prev) => ({
                ...prev,
                anStudiu: studyYearOptions.find((el) => el.id === e)!,
              }));
            }}
            selectProps={{
              label: tModules('schoolAdmin.subject.studyYear'),
              value: subjectTypeData.anStudiu?.id ?? '',
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={subjectTypeAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
