import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  Average,
  Calificative,
  ClosedSituationTableEntry,
  ClosingSituationRecord,
  EleviCorigenta,
  GradesAndAbsences,
  MarksAndAbsences,
  Motiv,
  PrimaryGradeOccurances,
} from 'library/types/Average';
import {
  apiCallConfigTypes,
  CollectionApiResponse,
  ParamsType,
} from 'library/types/Common';

export function AverageApi() {
  const { apiHandler } = JWTInterceptor();

  const getAverage = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Average[]> => {
    if (config) {
      var { params, id } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/medii-clasa/${id}`
        : `${serverUri()}/medii-clasa`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getGradesAndAbsences = async ({
    subjectId,
    studentId,
    isPrimaryGrade,
  }: {
    subjectId: number;
    studentId: number;
    isPrimaryGrade: boolean;
  }): Promise<GradesAndAbsences | MarksAndAbsences> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/inchidere${isPrimaryGrade ? '-primar' : ''}/${
        isPrimaryGrade ? 'calificative' : 'note'
      }-materie/${subjectId}/${studentId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const closeAverage = async ({
    subjectId,
    studentId,
    isPrimaryGrade,
    calificativ,
  }: {
    subjectId: number;
    studentId: number;
    isPrimaryGrade: boolean;
    calificativ?: string;
  }): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/inchidere${
        isPrimaryGrade ? '-primar' : ''
      }/medie/${subjectId}/${studentId}/`,
      data: isPrimaryGrade
        ? {
            calificativ,
          }
        : {},
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const getPrimaryGradeOccurances = async ({
    subjectId,
    studentId,
  }: {
    subjectId: number;
    studentId: number;
  }): Promise<PrimaryGradeOccurances> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/calificative-preponderente/${subjectId}/${studentId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const forcedCloseAverage = async ({
    subjectId,
    studentId,
    isPrimaryGrade,
    calificativ,
  }: {
    subjectId: number;
    studentId: number;
    isPrimaryGrade: boolean;
    calificativ?: string;
  }) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/inchidere${
        isPrimaryGrade ? '-primar' : ''
      }/medie/fortat/${subjectId}/${studentId}/`,
      data: isPrimaryGrade
        ? {
            calificativ,
          }
        : {},
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const unclosedAverage = async ({
    subjectId,
    studentId,
    isPrimaryGrade,
  }: {
    subjectId: number;
    studentId: number;
    isPrimaryGrade: boolean;
  }) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/inchidere${
        isPrimaryGrade ? '-primar' : ''
      }/medie/neincheiata/${subjectId}/${studentId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const getStudentsCorigenta = async (
    subjectId: number
  ): Promise<EleviCorigenta[]> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/elevi-corigenta/${subjectId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const setGradeCorigenta = async (
    averageId: number,
    gradeOrCalificativ: number | string,
    isPrimaryGrade: boolean
  ) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/set-corigenta${
        isPrimaryGrade ? '-primar' : ''
      }/${averageId}${!isPrimaryGrade ? '/' + `${gradeOrCalificativ}/` : '/'}`,
      data: isPrimaryGrade
        ? {
            calificativ: gradeOrCalificativ,
          }
        : {},
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: `The ${
        isPrimaryGrade ? 'calificativ' : 'grade'
      } has been successfully updated!`,
    });

    return response.data;
  };

  const setAbsenceCorigenta = async (averageId: number) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/set-absenta-corigenta/${averageId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The corigenta absence has been successfully set!',
    });

    return response.data;
  };

  const getClosingSituationForStudent = async (
    studentId: number
  ): Promise<ClosingSituationRecord> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/medii-elev/${studentId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const setCorigenta = async (averageId: number) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/setare-corigenta/${averageId}/`,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The corigenta has been successfully set!',
    });

    return response.data;
  };

  const delayClosingAverage = async (
    averageId: number,
    observatii: string,
    motiv: number
  ) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/medie-amanata/${averageId}/`,
      data: {
        observatii,
        motiv,
      },
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Closing the average has been successfully delayed!',
    });

    return response.data;
  };

  const promoteStudent = async ({
    studentId,
    observatii,
    purtare,
    isPrimaryGrade,
    calificativ,
  }: {
    studentId: number;
    observatii: string;
    purtare: string | number;
    isPrimaryGrade?: boolean;
    calificativ?: Calificative;
  }) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/promovare${
        isPrimaryGrade ? '-primar' : ''
      }/${studentId}/`,
      data: {
        observatii,
        calificativ,
        ...(isPrimaryGrade
          ? { calificativPurtare: purtare }
          : { notaPurtare: purtare }),
      },
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The student has been successfully promoted!',
    });

    return response.data;
  };

  const delaySituation = async ({
    isPrimaryGrade,
    studentId,
    observatii,
    motiv,
    purtare,
  }: {
    isPrimaryGrade: boolean;
    studentId: number;
    observatii: string;
    motiv: number;
    purtare: string | number;
  }) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/situatie-amanata/${studentId}/`,
      data: {
        observatii,
        motiv,
        ...(isPrimaryGrade
          ? { calificativPurtare: purtare }
          : { notaPurtare: purtare }),
      },
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The situation has been successfully delayed!',
    });

    return response.data;
  };

  const failStudent = async ({
    isPrimaryGrade,
    studentId,
    observatii,
    motiv,
    purtare,
  }: {
    isPrimaryGrade: boolean;
    studentId: number;
    observatii: string;
    motiv: number;
    purtare: string | number;
  }) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/repetenta/${studentId}/`,
      data: {
        observatii,
        motiv,
        ...(isPrimaryGrade
          ? { calificativPurtare: purtare }
          : { notaPurtare: purtare }),
      },
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Success!',
    });

    return response.data;
  };

  const getClosedSituation = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    ClosedSituationTableEntry | CollectionApiResponse<ClosedSituationTableEntry>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/inchidere-situatie/${id}`
        : `${serverUri()}/inchidere-situatie`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getSituationReasons = async (): Promise<
    CollectionApiResponse<Motiv>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/motive/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  return {
    closeAverage,
    getGradesAndAbsences,
    getPrimaryGradeOccurances,
    forcedCloseAverage,
    unclosedAverage,
    getAverage,
    setGradeCorigenta,
    setAbsenceCorigenta,
    getStudentsCorigenta,
    getClosingSituationForStudent,
    setCorigenta,
    delayClosingAverage,
    promoteStudent,
    delaySituation,
    failStudent,
    getClosedSituation,
    getSituationReasons,
  };
}
