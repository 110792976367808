import { SchoolApi } from 'api/school.api';
import { UserApi } from 'api/user.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { getInitialViewType } from 'library/services/auth.service';
import { SelectOption } from 'library/types/Common';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Login = (): JSX.Element => {
  const { t } = useTranslation('modules');
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const userState = userContext.state;
  let isAuthenticated = userState.account;

  const { login } = UserApi();
  const { getAvailableSchools } = SchoolApi();

  const [email, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [school, setSchool] = useState<number | string>();
  const [schoolOptions, setSchoolOptions] = useState<Array<SelectOption>>([]);

  const getAvailableSchoolsAction = async () => {
    const schools = await getAvailableSchools();

    setSchoolOptions(schools);
  };

  useEffect(() => {
    getAvailableSchoolsAction();
  }, []);

  const loginAction = async () => {
    if (!school || typeof school == 'string' || !email || !password) {
      return toast.error(t('auth.loginValidationErrorMessage'));
    }

    const response = await login({
      email,
      password,
      scoala: school,
    });

    if (!response.data) return;
    userContext.dispatch({
      type: UserContextActionTypes.SetAccount,
      value: {
        user: response.data.user,
        accessToken: response.data.token,
        selectedView: response.data.user.rolActiv,
      },
    });
  };

  return !isAuthenticated ? (
    <div
      style={{
        width: '400px',
      }}
      className="flex flex-col"
    >
      {process.env.NODE_ENV === 'development' && (
        <div className="flex gap-5">
          <div
            onClick={async () => {
              const response = await login({
                email: 'M.C.StimNoi@MEREUGGZZ.ro',
                password: 'pass',
                scoala: 4,
              });

              if (!response.data) return;
              userContext.dispatch({
                type: UserContextActionTypes.SetAccount,
                value: {
                  user: response.data.user,
                  accessToken: response.data.token,
                  selectedView: response.data.user.rolActiv,
                },
              });
            }}
          >
            ADMIN
          </div>
          <div
            onClick={async () => {
              const response = await login({
                email: 'andrei.mihai@ludosolutions.ro',
                password: 'pass',
                scoala: 4,
              });

              if (!response.data) return;
              userContext.dispatch({
                type: UserContextActionTypes.SetAccount,
                value: {
                  user: response.data.user,
                  accessToken: response.data.token,
                  selectedView: response.data.user.rolActiv,
                },
              });
            }}
          >
            DIRIG
          </div>
          <div
            onClick={async () => {
              const response = await login({
                email: 'popescu.maria@ludosolutions.ro',
                password: 'pass',
                scoala: 4,
              });

              if (!response.data) return;
              userContext.dispatch({
                type: UserContextActionTypes.SetAccount,
                value: {
                  user: response.data.user,
                  accessToken: response.data.token,
                  selectedView: response.data.user.rolActiv,
                },
              });
            }}
          >
            PARINTE
          </div>
          <div
            onClick={async () => {
              const response = await login({
                email: 'prof@example.com',
                password: 'pass',
                scoala: 2,
              });

              if (!response.data) return;
              userContext.dispatch({
                type: UserContextActionTypes.SetAccount,
                value: {
                  user: response.data.user,
                  accessToken: response.data.token,
                  selectedView: response.data.user.rolActiv,
                },
              });
            }}
          >
            PROF
          </div>
          <div
            onClick={async () => {
              const response = await login({
                email: 'elev1@smart-edu.ro',
                password: 'pass',
                scoala: 4,
              });

              if (!response.data) return;
              userContext.dispatch({
                type: UserContextActionTypes.SetAccount,
                value: {
                  user: response.data.user,
                  accessToken: response.data.token,
                  selectedView: response.data.user.rolActiv,
                },
              });
            }}
          >
            ELEV
          </div>
        </div>
      )}
      <RegularInput
        textFieldProps={{
          fullWidth: true,
          value: email,
          label: t('auth.username'),
          onChange: (e) => setUsername(e.target.value),
        }}
      ></RegularInput>
      <RegularInput
        textFieldProps={{
          type: 'password',
          fullWidth: true,
          value: password,
          label: t('auth.password'),
          onChange: (e) => setPassword(e.target.value),
        }}
      ></RegularInput>
      <RegularSelect
        options={schoolOptions}
        onChange={setSchool}
        selectProps={{
          label: t('auth.school'),
          value: school,
        }}
      ></RegularSelect>
      <ButtonComp
        onButtonClick={loginAction}
        buttonText="LOGIN"
        buttonProps={{
          variant: 'contained',
          defaultValue: 'Login',
        }}
        tailwindContainerClasses="flex justify-center"
      />
      {/* <div className="flex justify-center">
        <span
          onClick={() => {
            navigate('/reset-password');
          }}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
        >
          {t('auth.passwordForgotten')}
        </span>
      </div> */}
    </div>
  ) : (
    <Navigate to="/home" />
  );
};
