import { Tooltip, Typography } from '@mui/material';
import { AcademicRecordsApi } from 'api/academicRecords.api';
import { ClassApi } from 'api/class.api';
import { SchoolYearApi } from 'api/schoolYear.api';
import { UserApi } from 'api/user.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import dayjs from 'dayjs';
import {
  checkFields,
  compareSchoolYears,
  convertToRoman,
} from 'library/helpers/helper';
import {
  AcademicRecord,
  AcademicRecordJSON,
  AcademicRecordPrintVersion,
  AcademicRecordType,
  MateriiEntry,
} from 'library/types/AcademicRecords';
import { Class } from 'library/types/Class';
import { CollectionApiResponse } from 'library/types/Common';
import { SchoolYear } from 'library/types/SchoolYear';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { icons } from 'resources/images';

export const ManageAcademicRecords = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { getSchoolYear } = SchoolYearApi();
  const { getStudentsOfClass, getClaseDisponibileAnScolar } = ClassApi();
  const { getAcademicRecord } = AcademicRecordsApi();
  const { patchUser } = UserApi();

  const [schoolYears, setSchoolYears] = useState<SchoolYear[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [students, setStudents] = useState<
    Array<{
      id: number;
      nume: string;
      prenume: string;
    }>
  >([]);

  const [selectedSchoolYear, setSelectedSchoolYear] = useState(-1);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectedStudent, setSelectedStudent] = useState(-1);

  const [selectedStep, setSelectedStep] = useState<0 | 1 | 2 | 3 | 4>(0);

  const [observations, setObservations] = useState<string>('');

  const [academicRecordData, setAcademicRecordData] =
    useState<AcademicRecord | null>(null);
  const [academicRecordJSONData, setAcademicRecordJSONData] =
    useState<AcademicRecordJSON>({
      [AcademicRecordType['I - VIII']]: {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
      [AcademicRecordType['IX - XII']]: {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
    });

  const [recalculateGeneralAverages, setRecalculateGeneralAverages] =
    useState(false);

  useEffect(() => {
    (async () => {
      const schoolYears =
        (await getSchoolYear()) as CollectionApiResponse<SchoolYear>;
      setSchoolYears(schoolYears['hydra:member']);

      // to remove
      // setSelectedSchoolYear(schoolYears['hydra:member'][1].id);
    })();
  }, []);

  useEffect(() => {
    if (selectedSchoolYear === -1) {
      setSelectedClass(-1);
      return;
    }

    (async () => {
      const classes = await getClaseDisponibileAnScolar(selectedSchoolYear);
      setClasses(classes);

      // to remove
      // setSelectedClass(classes[3].id);
    })();
  }, [selectedSchoolYear]);

  useEffect(() => {
    if (selectedClass === -1) {
      setSelectedStudent(-1);
      return;
    }

    (async () => {
      const students = await getStudentsOfClass(selectedClass);
      setStudents(students);

      // to remove
      // setSelectedStudent(students[4].id);
    })();
  }, [selectedClass]);

  useEffect(() => {
    if (selectedStudent === -1) {
      setSelectedStep(0);
      return;
    }

    let academicRecordJSON: AcademicRecordJSON = {
      [AcademicRecordType['I - VIII']]: {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
      [AcademicRecordType['IX - XII']]: {
        schoolSituation: [],
        gradeSituation: {
          mediiMaterii: [],
          mediiGenerale: [],
        },
        mentions: '',
      },
    };

    (async () => {
      const academicRecord = await getAcademicRecord(selectedStudent);
      setAcademicRecordData(academicRecord);

      if (academicRecord.jsonInfo) {
        academicRecordJSON = academicRecord.jsonInfo as AcademicRecordJSON;
      }

      let { gradeSituation, schoolSituation } =
        academicRecordJSON[
          AcademicRecordType[academicRecord.type as AcademicRecordType]
        ];

      const jsonMediiMap = new Map(
        gradeSituation.mediiMaterii.map((item) => [item.numeMaterie, item])
      );

      // Merge the arrays
      const mergedArray = academicRecord.mediiMaterii.map((item) => {
        if (jsonMediiMap.has(item.numeMaterie)) {
          // Merge the medii objects, prioritizing values from the academicRecordJSON.gradeSituation.mediiMaterii
          const mergedMedii = {
            ...item.medii,
            ...jsonMediiMap.get(item.numeMaterie)!.medii,
          };
          return {
            ...item,
            medii: mergedMedii,
          };
        }
        return item;
      });

      // Add items from the academicRecordJSON.gradeSituation.mediiMaterii that are not in academicRecord.mediiMaterii
      gradeSituation.mediiMaterii.forEach((item) => {
        if (
          !mergedArray.some(
            (mergedItem) => mergedItem.numeMaterie === item.numeMaterie
          )
        ) {
          mergedArray.push(item);
        }
      });

      gradeSituation.mediiMaterii = mergedArray;

      const mediiGenerale = [
        ...academicRecord.mediiGenerale,
        ...gradeSituation.mediiGenerale,
      ].sort((a, b) => a.anMaterie.localeCompare(b.anMaterie));

      const uniqueMediiGeneraleMap = new Map();
      for (const entry of mediiGenerale) {
        uniqueMediiGeneraleMap.set(entry.anMaterie, entry);
      }
      gradeSituation.mediiGenerale = Array.from(
        uniqueMediiGeneraleMap.values()
      );

      // to remove
      // const schoolRecords = generateSchoolRecordMockData(8);
      // academicRecordJSON['I - VIII'].schoolSituation = schoolRecords;
      // until here

      if (gradeSituation.mediiGenerale.length === 0) {
        gradeSituation.mediiGenerale = [
          {
            anMaterie: '',
            medie: '',
          },
        ];
      }

      if (schoolSituation.length === 0) {
        schoolSituation = [
          {
            schoolName: '',
            volume: {
              noOfPages: '',
              matricolNumber: '',
              volumeNumber: '',
            },
            yearAndEducationFormat: '',
            schoolYear: '',
            observations: '',
          },
        ];
      } else {
        schoolSituation = schoolSituation.sort(compareSchoolYears);
      }

      // to remove
      // const { mediiGenerale: mediiGeneraleMock, mediiMaterii } =
      //   generateAcademicRecordsMockData();

      // academicRecordJSON['I - VIII'].gradeSituation.mediiGenerale =
      //   mediiGeneraleMock;
      // academicRecordJSON['I - VIII'].gradeSituation.mediiMaterii = mediiMaterii;
      // until here

      setAcademicRecordJSONData(academicRecordJSON);
    })();
  }, [selectedStudent]);

  useEffect(() => {
    if (!academicRecordData) {
      setSelectedStep(0);
      return;
    }
    setSelectedStep(1);
  }, [academicRecordData]);

  useEffect(() => {
    if (!recalculateGeneralAverages || !academicRecordData) return;

    const { gradeSituation } =
      academicRecordJSONData[
        AcademicRecordType[academicRecordData.type as AcademicRecordType]
      ];
    const { mediiMaterii, mediiGenerale } = gradeSituation;

    const mediiMateriiMaping = [];

    for (let i = 0; i < mediiMaterii.length; i++) {
      const item: MateriiEntry = {
        numeMaterie: mediiMaterii[i].numeMaterie,
        medii: {},
      };

      Object.keys(mediiMaterii[i].medii).forEach((key) => {
        if (parseInt(key) > 4) {
          item.medii[key] = mediiMaterii[i].medii[key] || '0';
        }
      });

      mediiMateriiMaping.push(item);
    }

    const mediiGeneraleMapping = mediiMateriiMaping.reduce((acc, item) => {
      Object.keys(item.medii).forEach((key) => {
        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(parseFloat(item.medii[key]));
      });

      return acc;
    }, {} as { [key: string]: (number | string)[] });

    const updatedMediiGenerale = Object.entries(mediiGeneraleMapping).map(
      ([key, values]) => {
        const finalValue =
          (values as number[]).reduce((acc, item) => acc + item, 0) /
          values.length;

        return {
          anMaterie: key,
          medie: finalValue ? finalValue.toFixed(2) : '',
        };
      }
    );

    setAcademicRecordJSONData({
      ...academicRecordJSONData,
      [AcademicRecordType[academicRecordData.type as AcademicRecordType]]: {
        ...academicRecordJSONData[
          AcademicRecordType[academicRecordData.type as AcademicRecordType]
        ],
        gradeSituation: {
          ...academicRecordJSONData[
            AcademicRecordType[academicRecordData.type as AcademicRecordType]
          ].gradeSituation,
          mediiGenerale: updatedMediiGenerale,
        },
      },
    });

    setRecalculateGeneralAverages(false);
  }, [recalculateGeneralAverages]);

  const getNumberOfColumnsForGrades = () => {
    if (!academicRecordData) return;
    switch (academicRecordData.type) {
      case AcademicRecordType['I - VIII']:
        return 8;

      case AcademicRecordType['IX - XII']:
        return 12;

      default:
        throw new Error('Invalid type');
    }
  };

  const saveValuesAndGenerate = async () => {
    if (selectedStudent === -1 || !academicRecordData) return;

    const printInfo: AcademicRecordPrintVersion = {
      schoolName: academicRecordData.schoolName,
      cif: academicRecordData.cif,
      type: academicRecordData.type,
      fullName: academicRecordData.fullName,
      cnp: academicRecordData.cnp,
      dateOfBirth: academicRecordData.dateOfBirth,
      birthAddress: academicRecordData.birthAddress,
      jsonInfo: academicRecordJSONData,
      nationality: academicRecordData.nationality,
      motherFullName: academicRecordData.motherFullName,
      fatherFullName: academicRecordData.fatherFullName,
      secretaryName: academicRecordData.secretaryName,
      directorName: academicRecordData.directorName,
    };

    const academicRecordJSON = JSON.stringify(printInfo);

    localStorage.setItem('academicRecordPrintInfo', academicRecordJSON);

    try {
      await patchUser(selectedStudent, {
        foaieMatricolaInfo: academicRecordJSONData,
      });

      setSelectedSchoolYear(-1);

      window.open('/academic-records/print', '_blank');
    } catch (e) {
      console.log('err: ', e);
    }
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        {selectedStep === 0 && (
          <div
            style={{
              width: '600px',
            }}
            className="select-student-step flex flex-col"
          >
            <Typography variant="h3" className="flex justify-center">
              {tModules('academicRecords.selectStudentStep')}
            </Typography>
            <RegularSelect
              options={schoolYears.map((e) => ({
                value: e.id,
                label: `${e.anInceput}`,
              }))}
              onChange={(e) => {
                setSelectedSchoolYear(e);
              }}
              selectProps={{
                label: tModules('academicRecords.schoolYear'),
                value: selectedSchoolYear,
                fullWidth: true,
                disabled: schoolYears.length === 0,
              }}
            />
            <RegularSelect
              options={classes.map((e) => ({
                value: e.id,
                label: e.codClasa as string,
              }))}
              onChange={(e) => {
                const selectedClass = classes.find((el) => el.id === e);
                setSelectedClass(selectedClass!.id);
              }}
              selectProps={{
                label: tModules('academicRecords.class'),
                value: selectedClass,
                fullWidth: true,
                disabled: classes.length === 0 || selectedSchoolYear === -1,
              }}
            />
            <RegularSelect
              options={students.map((e) => ({
                value: e.id,
                label: `${e.nume} ${e.prenume}`,
              }))}
              onChange={(e) => {
                setSelectedStudent(e);
              }}
              selectProps={{
                label: tModules('academicRecords.student'),
                value: selectedStudent,
                fullWidth: true,
                disabled: students.length === 0 || selectedClass === -1,
              }}
            />
          </div>
        )}
        {selectedStep === 1 && academicRecordData && (
          <div
            style={{
              width: '600px',
            }}
            className="first-step flex flex-col"
          >
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStudent(-1);
                  setSelectedStep(0);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('academicRecords.step1')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  const { gradeSituation } =
                    academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ];
                  const { mediiMaterii } = gradeSituation;
                  let shouldContinue = true;
                  mediiMaterii.forEach((item) => {
                    if (Object.keys(item.medii).length === 0) {
                      toast.error(
                        tModules('academicRecords.noGradesForSubjectError')
                      );
                      shouldContinue = false;
                    }

                    if (Object.values(item.medii).every((e) => e === '')) {
                      toast.error(
                        tModules('academicRecords.noGradesForSubjectError')
                      );
                      shouldContinue = false;
                    }
                  });

                  if (shouldContinue) {
                    setSelectedStep(2);
                  }
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.schoolName'),
                disabled: true,
                value: academicRecordData.schoolName,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.cif'),
                disabled: true,
                value: academicRecordData.cif,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.type'),
                disabled: true,
                value: academicRecordData.type,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.fullName'),
                disabled: true,
                value: academicRecordData.fullName,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.cnp'),
                disabled: true,
                value: academicRecordData.cnp,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.birthAddress'),
                disabled: true,
                value: academicRecordData.birthAddress,
              }}
            />
            <DatePickerInput
              datePickerProps={{
                slotProps: {
                  textField: { fullWidth: true },
                },
                label: tModules('academicRecords.dateOfBirth'),
                value: dayjs(academicRecordData.dateOfBirth),
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.nationality'),
                disabled: true,
                value: academicRecordData.nationality,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.fatherFullName'),
                disabled: true,
                value: academicRecordData.fatherFullName,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('academicRecords.motherFullName'),
                disabled: true,
                value: academicRecordData.motherFullName,
              }}
            />
          </div>
        )}
        {academicRecordData && selectedStep === 2 && (
          <div className="second-step flex flex-col">
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => setSelectedStep(1)}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('academicRecords.step2')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  let shouldContinue = true;
                  // check if all fields of all schools are valid except observations\
                  const { schoolSituation } =
                    academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ];
                  schoolSituation.forEach((item) => {
                    if (
                      !checkFields(
                        tModules,
                        'academicRecords.allFieldsExceptObservations',
                        item,
                        ['observations']
                      )
                    ) {
                      shouldContinue = false;
                    }
                  });

                  if (shouldContinue) {
                    setSelectedStep(3);
                  }
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <div className="container mx-auto">
              {!!academicRecordJSONData[
                AcademicRecordType[
                  academicRecordData.type as AcademicRecordType
                ]
              ].schoolSituation.length && (
                <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th
                        style={{
                          maxWidth: '12rem',
                        }}
                        rowSpan={2}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        School Name
                      </th>
                      <th
                        colSpan={3}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                      >
                        Volum Matricol
                      </th>
                      <th
                        style={{
                          maxWidth: '6rem',
                        }}
                        rowSpan={2}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Year and Education Format
                      </th>
                      <th
                        style={{
                          maxWidth: '6rem',
                        }}
                        rowSpan={2}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        School Year
                      </th>
                      <th
                        style={{
                          maxWidth: '12rem',
                        }}
                        rowSpan={2}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Observations
                      </th>
                      <th
                        rowSpan={2}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Actiuni
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          maxWidth: '6rem',
                        }}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        No of Pages
                      </th>
                      <th
                        style={{
                          maxWidth: '6rem',
                        }}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Matricol Number
                      </th>
                      <th
                        style={{
                          maxWidth: '6rem',
                        }}
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Volume Number
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ].schoolSituation.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '12rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                        >
                          <input
                            style={{
                              width: '11rem',
                            }}
                            type="text"
                            value={item.schoolName}
                            onChange={(e) => {
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].schoolName =
                                e.target.value;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '6rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '5rem',
                            }}
                            type="text"
                            value={item.volume.noOfPages || ''}
                            onChange={(e) => {
                              const updatedVolume = {
                                ...item.volume,
                              };
                              updatedVolume.noOfPages = e.target.value;
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].volume =
                                updatedVolume;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '6rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '5rem',
                            }}
                            type="text"
                            value={item.volume.matricolNumber || ''}
                            onChange={(e) => {
                              const updatedVolume = {
                                ...item.volume,
                              };
                              updatedVolume.matricolNumber = e.target.value;
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].volume =
                                updatedVolume;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '6rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '5rem',
                            }}
                            type="text"
                            value={item.volume.volumeNumber || ''}
                            onChange={(e) => {
                              const updatedVolume = {
                                ...item.volume,
                              };
                              updatedVolume.volumeNumber = e.target.value;
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].volume =
                                updatedVolume;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '6rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '5rem',
                            }}
                            type="text"
                            value={item.yearAndEducationFormat || ''}
                            onChange={(e) => {
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[
                                index
                              ].yearAndEducationFormat = e.target.value;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '6rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '5rem',
                            }}
                            type="text"
                            value={item.schoolYear || ''}
                            onChange={(e) => {
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].schoolYear =
                                e.target.value;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '12rem',
                          }}
                          className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          <input
                            style={{
                              width: '11rem',
                            }}
                            type="text"
                            value={item.observations || ''}
                            onChange={(e) => {
                              const updatedSchoolSituation = [
                                ...academicRecordJSONData[
                                  AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]
                                ].schoolSituation,
                              ];
                              updatedSchoolSituation[index].observations =
                                e.target.value;
                              setAcademicRecordJSONData({
                                ...academicRecordJSONData,
                                [AcademicRecordType[
                                  academicRecordData.type as AcademicRecordType
                                ]]: {
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ],
                                  schoolSituation: updatedSchoolSituation,
                                },
                              });
                            }}
                          />
                        </td>
                        <td>
                          <div className="flex gap-2 justify-center">
                            <Tooltip arrow title="Sterge rand">
                              <img
                                onClick={() => {
                                  const updatedSchoolSituation = [
                                    ...academicRecordJSONData[
                                      AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]
                                    ].schoolSituation,
                                  ];
                                  updatedSchoolSituation.splice(index, 1);
                                  setAcademicRecordJSONData({
                                    ...academicRecordJSONData,
                                    [AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]]: {
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ],
                                      schoolSituation: updatedSchoolSituation,
                                    },
                                  });
                                }}
                                className="cursor-pointer"
                                src={icons.general.redMark}
                                width={25}
                              />
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="flex w-full items-center flex-col">
              <ButtonComp
                onButtonClick={() => {
                  const updatedSchoolSituation = [
                    ...academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ].schoolSituation,
                  ];
                  updatedSchoolSituation.push({
                    schoolName: '',
                    volume: {
                      noOfPages: '',
                      matricolNumber: '',
                      volumeNumber: '',
                    },
                    yearAndEducationFormat: '',
                    schoolYear: '',
                    observations: '',
                  });
                  setAcademicRecordJSONData({
                    ...academicRecordJSONData,
                    [AcademicRecordType[
                      academicRecordData.type as AcademicRecordType
                    ]]: {
                      ...academicRecordJSONData[
                        AcademicRecordType[
                          academicRecordData.type as AcademicRecordType
                        ]
                      ],
                      schoolSituation: updatedSchoolSituation,
                    },
                  });
                }}
                buttonText={tModules('academicRecords.addSchoolSituationEntry')}
                buttonProps={{
                  variant: 'contained',
                }}
                tailwindContainerClasses="flex justify-end"
              />
            </div>
          </div>
        )}
        {academicRecordData && selectedStep === 3 && (
          <div className="second-step flex flex-col">
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => setSelectedStep(2)}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('academicRecords.step3')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  const { gradeSituation } =
                    academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ];
                  const { mediiMaterii } = gradeSituation;
                  let shouldContinue = true;
                  mediiMaterii.forEach((item) => {
                    if (Object.keys(item.medii).length === 0) {
                      toast.error(
                        tModules('academicRecords.noGradesForSubjectError')
                      );
                      shouldContinue = false;
                    }

                    if (Object.values(item.medii).every((e) => e === '')) {
                      toast.error(
                        tModules('academicRecords.noGradesForSubjectError')
                      );
                      shouldContinue = false;
                    }
                  });

                  if (shouldContinue) {
                    setSelectedStep(4);
                  }
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>

            <div className="container mx-auto">
              {!!academicRecordJSONData[
                AcademicRecordType[
                  academicRecordData.type as AcademicRecordType
                ]
              ].gradeSituation.mediiMaterii.length &&
                academicRecordData?.type && (
                  <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                    <thead className="bg-gray-800 text-white">
                      <tr>
                        <th
                          style={{
                            maxWidth: '9rem',
                          }}
                          rowSpan={2}
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                        >
                          Disciplinele de invatamant
                        </th>
                        <th
                          colSpan={
                            (getNumberOfColumnsForGrades() as number) + 1
                          }
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                        >
                          Media pe ani de studii
                        </th>
                      </tr>
                      <tr>
                        {Array.from(
                          { length: getNumberOfColumnsForGrades() as number },
                          (_, i) => i + 1
                        ).map((item, index) => (
                          <th
                            key={index}
                            className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          >
                            {convertToRoman(item)}
                          </th>
                        ))}
                        <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                          Actiuni
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {academicRecordJSONData[
                        AcademicRecordType[
                          academicRecordData.type as AcademicRecordType
                        ]
                      ].gradeSituation.mediiMaterii.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          <td
                            style={{
                              maxWidth: '9rem',
                            }}
                            className="px-3 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            <input
                              style={{
                                width: '8rem',
                              }}
                              type="text"
                              value={item.numeMaterie}
                              onChange={(e) => {
                                const updatedMediiMaterii = [
                                  ...academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ].gradeSituation.mediiMaterii,
                                ];
                                updatedMediiMaterii[rowIndex].numeMaterie =
                                  e.target.value;
                                setAcademicRecordJSONData({
                                  ...academicRecordJSONData,
                                  [AcademicRecordType[
                                    academicRecordData.type as AcademicRecordType
                                  ]]: {
                                    ...academicRecordJSONData[
                                      AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]
                                    ],
                                    gradeSituation: {
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ].gradeSituation,
                                      mediiMaterii: updatedMediiMaterii,
                                    },
                                  },
                                });
                              }}
                            />
                          </td>
                          {Array.from(
                            {
                              length: getNumberOfColumnsForGrades() as number,
                            },
                            (_, colIndex) => colIndex + 1
                          ).map((gradesItem) => (
                            <td
                              style={{
                                maxWidth: '6rem',
                              }}
                              key={`grade-${rowIndex}-${gradesItem}`}
                              className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                            >
                              <input
                                style={{
                                  width: '5rem',
                                }}
                                type="text"
                                value={item.medii[gradesItem.toString()] || ''}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  let formattedValue = '';

                                  if (gradesItem > 4) {
                                    // Allow only numbers and one dot
                                    if (value.startsWith('.')) {
                                      value = value.slice(1);
                                    }
                                    const validValue = value.replace(
                                      /[^0-9.]/g,
                                      ''
                                    );
                                    // Ensure there is only one dot
                                    const parts = validValue.split('.');
                                    formattedValue =
                                      parts.length > 2
                                        ? `${parts[0]}.${parts
                                            .slice(1)
                                            .join('')}`
                                        : validValue;
                                  } else {
                                    formattedValue = value.replace(
                                      /[^a-zA-Z\s]/g,
                                      ''
                                    );
                                  }

                                  if (
                                    !formattedValue &&
                                    !item.medii[gradesItem.toString()]
                                  ) {
                                    return;
                                  }

                                  const updatedMediiMaterii = [
                                    ...academicRecordJSONData[
                                      AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]
                                    ].gradeSituation.mediiMaterii,
                                  ];
                                  updatedMediiMaterii[rowIndex].medii[
                                    gradesItem.toString()
                                  ] = formattedValue;
                                  setAcademicRecordJSONData({
                                    ...academicRecordJSONData,
                                    [AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]]: {
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ],
                                      gradeSituation: {
                                        ...academicRecordJSONData[
                                          AcademicRecordType[
                                            academicRecordData.type as AcademicRecordType
                                          ]
                                        ].gradeSituation,
                                        mediiMaterii: updatedMediiMaterii,
                                      },
                                    },
                                  });
                                }}
                                onBlur={(e) => {
                                  let value = e.target.value;

                                  if (gradesItem > 4) {
                                    if (value.endsWith('.')) {
                                      value = value.slice(0, -1);
                                    }
                                    // Convert value to a number and check if it is a valid number
                                    const numberValue = parseFloat(value);
                                    if (!isNaN(numberValue)) {
                                      value = numberValue.toFixed(2);
                                    } else {
                                      value = '';
                                    }
                                  }

                                  const updatedMediiMaterii = [
                                    ...academicRecordJSONData[
                                      AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]
                                    ].gradeSituation.mediiMaterii,
                                  ];
                                  updatedMediiMaterii[rowIndex].medii[
                                    gradesItem.toString()
                                  ] = value;
                                  setAcademicRecordJSONData({
                                    ...academicRecordJSONData,
                                    [AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]]: {
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ],
                                      gradeSituation: {
                                        ...academicRecordJSONData[
                                          AcademicRecordType[
                                            academicRecordData.type as AcademicRecordType
                                          ]
                                        ].gradeSituation,
                                        mediiMaterii: updatedMediiMaterii,
                                      },
                                    },
                                  });

                                  if (gradesItem > 4) {
                                    setRecalculateGeneralAverages(true);
                                  }
                                }}
                              />
                            </td>
                          ))}
                          <td>
                            <div className="flex gap-2 justify-center">
                              <Tooltip arrow title="Sterge rand">
                                <img
                                  onClick={() => {
                                    const updatedMediiMaterii = [
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ].gradeSituation.mediiMaterii,
                                    ];
                                    updatedMediiMaterii.splice(rowIndex, 1);
                                    setAcademicRecordJSONData({
                                      ...academicRecordJSONData,
                                      [AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]]: {
                                        ...academicRecordJSONData[
                                          AcademicRecordType[
                                            academicRecordData.type as AcademicRecordType
                                          ]
                                        ],
                                        gradeSituation: {
                                          ...academicRecordJSONData[
                                            AcademicRecordType[
                                              academicRecordData.type as AcademicRecordType
                                            ]
                                          ].gradeSituation,
                                          mediiMaterii: updatedMediiMaterii,
                                        },
                                      },
                                    });
                                  }}
                                  className="cursor-pointer"
                                  src={icons.general.redMark}
                                  width={25}
                                />
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          className="px-3 py-3 whitespace-nowrap text-sm font-medium"
                          style={{
                            maxWidth: '6rem',
                          }}
                        >
                          Medii generale
                        </td>
                        {Array.from(
                          {
                            length: getNumberOfColumnsForGrades() as number,
                          },
                          (_, colIndex) => colIndex + 1
                        ).map((gradesItem) => {
                          return (
                            <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                              <input
                                style={{
                                  width: '5rem',
                                }}
                                type="text"
                                value={
                                  academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ].gradeSituation.mediiGenerale.find(
                                    (e) => parseInt(e.anMaterie) === gradesItem
                                  )?.medie || ''
                                }
                                onChange={(e) => {
                                  let value = e.target.value;
                                  let formattedValue = '';

                                  if (gradesItem > 4) {
                                    // Allow only numbers and one dot
                                    if (value.startsWith('.')) {
                                      value = value.slice(1);
                                    }
                                    const validValue = value.replace(
                                      /[^0-9.]/g,
                                      ''
                                    );
                                    // Ensure there is only one dot
                                    const parts = validValue.split('.');
                                    formattedValue =
                                      parts.length > 2
                                        ? `${parts[0]}.${parts
                                            .slice(1)
                                            .join('')}`
                                        : validValue;
                                  } else {
                                    formattedValue = value.replace(
                                      /[^a-zA-Z\s]/g,
                                      ''
                                    );
                                  }

                                  const updatedMediiGenerale = [
                                    ...academicRecordJSONData[
                                      AcademicRecordType[
                                        academicRecordData.type as AcademicRecordType
                                      ]
                                    ].gradeSituation.mediiGenerale,
                                  ];
                                  const index = academicRecordJSONData[
                                    AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]
                                  ].gradeSituation.mediiGenerale.findIndex(
                                    (e) => parseInt(e.anMaterie) === gradesItem
                                  );

                                  if (index !== -1) {
                                    updatedMediiGenerale[index].medie =
                                      formattedValue;
                                  } else {
                                    updatedMediiGenerale.push({
                                      anMaterie: gradesItem.toString(),
                                      medie: formattedValue,
                                    });
                                  }
                                  setAcademicRecordJSONData({
                                    ...academicRecordJSONData,
                                    [AcademicRecordType[
                                      academicRecordData.type as AcademicRecordType
                                    ]]: {
                                      ...academicRecordJSONData[
                                        AcademicRecordType[
                                          academicRecordData.type as AcademicRecordType
                                        ]
                                      ],
                                      gradeSituation: {
                                        ...academicRecordJSONData[
                                          AcademicRecordType[
                                            academicRecordData.type as AcademicRecordType
                                          ]
                                        ].gradeSituation,
                                        mediiGenerale: updatedMediiGenerale,
                                      },
                                    },
                                  });
                                }}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>
            <div className="flex w-full items-center flex-col">
              <ButtonComp
                onButtonClick={() => {
                  const updatedMediiMaterii = [
                    ...academicRecordJSONData[
                      AcademicRecordType[
                        academicRecordData.type as AcademicRecordType
                      ]
                    ].gradeSituation.mediiMaterii,
                  ];
                  updatedMediiMaterii.push({
                    numeMaterie: '',
                    medii: {},
                  });
                  setAcademicRecordJSONData({
                    ...academicRecordJSONData,
                    [AcademicRecordType[
                      academicRecordData.type as AcademicRecordType
                    ]]: {
                      ...academicRecordJSONData[
                        AcademicRecordType[
                          academicRecordData.type as AcademicRecordType
                        ]
                      ],
                      gradeSituation: {
                        ...academicRecordJSONData[
                          AcademicRecordType[
                            academicRecordData.type as AcademicRecordType
                          ]
                        ].gradeSituation,
                        mediiMaterii: updatedMediiMaterii,
                      },
                    },
                  });
                }}
                buttonText={tModules('academicRecords.addSubject')}
                buttonProps={{
                  variant: 'contained',
                }}
                tailwindContainerClasses="flex justify-end"
              />
            </div>
          </div>
        )}
        {academicRecordData && selectedStep === 4 && (
          <div className="flex flex-col">
            <div
              style={{
                width: '600px',
              }}
              className="fourth-step flex items-center justify-between"
            >
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(3);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('academicRecords.step4')}
              </Typography>
              <ButtonComp
                onButtonClick={saveValuesAndGenerate}
                buttonText={tCommon('inputs.generateAcademicRecord')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <>
              <RegularInput
                isOptional={true}
                textFieldProps={{
                  fullWidth: true,
                  rows: 25,
                  multiline: true,
                  label: tModules('academicRecords.observations'),
                  value: observations,
                  onChange: (e) => {
                    setObservations(e.target.value);
                  },
                }}
              />
            </>
          </div>
        )}
      </div>
    </div>
  );
};
