import { CommonApi } from 'api/common.api';
import { SchoolApi } from 'api/school.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import {
  UtilityContext,
  UtilityContextActionTypes,
} from 'context/UtilityContext';
import { getStringValuesOfEnum, isObjectValid } from 'library/helpers/helper';
import {
  GenericResources,
  RequiredResources,
  SchoolPageViewTypes,
} from 'library/types/Common';
import { School } from 'library/types/School';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const IndividualSchoolModule = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const utilityContext = useContext(UtilityContext);
  const { pageViewType } = params;
  const isView = pageViewType === SchoolPageViewTypes.View;

  const { getSchool, editSchool } = SchoolApi();

  const { getResources } = CommonApi();

  const userContext = useContext(UserContext);
  const _id = userContext.state.account!.user.scoala.id;
  const [schoolData, setSchoolData] = useState<School | undefined>();

  const [schoolResources, setSchoolResources] = useState<
    Partial<GenericResources> | undefined
  >();

  const [autocompleteInputValues, setAutocompleteInputValues] = useState<{
    localitate: string;
    judet: string;
    tara: string;
    profilScoala: string;
  }>({
    localitate: '',
    judet: '',
    tara: '',
    profilScoala: '',
  });

  useEffect(() => {
    if (!getStringValuesOfEnum(SchoolPageViewTypes).includes(pageViewType))
      navigate(`/individual-school/view/${_id}`);

    if (!userContext.state.account) return;

    (async () => {
      const schoolResources = await getResources({
        [RequiredResources.Tari]: 0,
        [RequiredResources.ProfileScoala]: 0,
      });
      setSchoolResources(schoolResources);
      if (!_id || isNaN(_id)) return;
      try {
        var school = (await getSchool({ id: _id })) as School;
      } catch (e) {
        return navigate('/home');
      }
      setSchoolData({
        ...school,
        cicluPrimar: !!school.cicluPrimar,
        cicluGimnazial: !!school.cicluGimnazial,
        cicluLiceal: !!school.cicluLiceal,
      });
      utilityContext.dispatch({
        type: UtilityContextActionTypes.ApiLoaded,
      });
    })();
  }, []);

  const isActionValid = () => {
    if (isView || !_id || !schoolData) return false;

    const { cicluGimnazial, cicluLiceal, cicluPrimar } = schoolData;

    if (!isObjectValid(schoolData)) {
      toast.error('All fields must be filled!');
      return false;
    }

    if (!cicluGimnazial && !cicluLiceal && !cicluPrimar) {
      toast.error('A school must have at least one active cycle.');
      return false;
    }
    return true;
  };

  const schoolAction = async (id: number) => {
    if (!isActionValid()) return;

    const {
      adresa,
      judet,
      tara,
      numeScoala,
      profilScoala,
      localitate,
      cicluGimnazial,
      cicluLiceal,
      cicluPrimar,
      cui,
    } = schoolData!;

    const objectPayload = {
      adresa: adresa!,
      cicluPrimar: !!cicluPrimar,
      cicluGimnazial: !!cicluGimnazial,
      cicluLiceal: !!cicluLiceal,
      localitate: `/api/localitati/${localitate!.id}`,
      judet: `/api/judete/${judet!.id}`,
      tara: `/api/tari/${tara!.id}`,
      numeScoala: numeScoala,
      profilScoala: `/api/profileScoli/${profilScoala!.id}`,
      cui,
    };

    await editSchool(id, objectPayload);
  };

  return (
    <div className="page-container">
      {schoolData && schoolResources && (
        <div className="flex items-center flex-col">
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.school.schoolName'),
                value: schoolData.numeScoala,
                disabled: isView,
                onChange: (e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      numeScoala: e.target.value,
                    };
                  });
                },
              }}
            />
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.schoolProfile'),
                  value: schoolData.profilScoala?.tipProfilScoala,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: isView,
                  options: schoolResources.profileScoala!.map((e) => ({
                    nume: e.tipProfilScoala,
                    id: e.id,
                  })),
                  value: schoolData.profilScoala
                    ? {
                        nume: schoolData.profilScoala.tipProfilScoala,
                        id: schoolData.profilScoala.id,
                      }
                    : null,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setSchoolData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        profilScoala: newValue
                          ? {
                              tipProfilScoala: newValue.nume,
                              id: newValue.id,
                            }
                          : null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.profilScoala,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        profilScoala: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.schoolProfile'),
                }}
              />
            )}
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.school.address'),
                value: schoolData.adresa,
                disabled: isView,
                onChange: (e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      adresa: e.target.value,
                    };
                  });
                },
              }}
            />
            <RegularInput
              isOptional={true}
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.school.legalIdentificationNumber'),
                value: schoolData.cui,
                disabled: isView,
                onChange: (e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      cui: e.target.value,
                    };
                  });
                },
              }}
            />
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.primaryCycle'),
                  value: schoolData.cicluPrimar
                    ? tCommon('basic.yes')
                    : tCommon('basic.no'),
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={[
                  {
                    label: tCommon('basic.yes'),
                    value: true,
                  },
                  {
                    label: tCommon('basic.no'),
                    value: false,
                  },
                ]}
                onChange={(e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      cicluPrimar: e,
                    };
                  });
                }}
                selectProps={{
                  label: tModules('schoolAdmin.school.primaryCycle'),
                  value: schoolData.cicluPrimar,
                  fullWidth: true,
                  disabled: isView,
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.secondaryCycle'),
                  value: schoolData.cicluGimnazial
                    ? tCommon('basic.yes')
                    : tCommon('basic.no'),
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={[
                  {
                    label: tCommon('basic.yes'),
                    value: true,
                  },
                  {
                    label: tCommon('basic.no'),
                    value: false,
                  },
                ]}
                onChange={(e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      cicluGimnazial: e,
                    };
                  });
                }}
                selectProps={{
                  label: tModules('schoolAdmin.school.secondaryCycle'),
                  value: schoolData.cicluGimnazial,
                  fullWidth: true,
                  disabled: isView,
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.tertiaryCycle'),
                  value: schoolData.cicluLiceal
                    ? tCommon('basic.yes')
                    : tCommon('basic.no'),
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={[
                  {
                    label: tCommon('basic.yes'),
                    value: true,
                  },
                  {
                    label: tCommon('basic.no'),
                    value: false,
                  },
                ]}
                onChange={(e) => {
                  setSchoolData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      cicluLiceal: e,
                    };
                  });
                }}
                selectProps={{
                  label: tModules('schoolAdmin.school.tertiaryCycle'),
                  value: schoolData.cicluLiceal,
                  fullWidth: true,
                  disabled: isView,
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.country'),
                  value: schoolData.tara?.nume,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: isView,
                  options: schoolResources.tari!,
                  value: schoolData.tara,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setSchoolData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        tara: newValue,
                        judet: null,
                        localitate: null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.tara,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        tara: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.country'),
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.county'),
                  value: schoolData.judet?.nume,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: !schoolData.tara || isView,
                  options: schoolData.tara
                    ? schoolResources.tari!.find(
                        (e) => e.id === schoolData.tara!.id
                      )!.judete
                    : [],
                  value: schoolData.judet,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setSchoolData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        judet: newValue,
                        localitate: null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.judet,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        judet: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.county'),
                }}
              />
            )}

            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.city'),
                  value: schoolData.localitate?.nume,
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: !schoolData.tara || !schoolData.judet || isView,
                  options: schoolData.judet
                    ? schoolResources
                        .tari!.find((e) => e.id === schoolData.tara!.id)!
                        .judete.find((e) => e.id === schoolData.judet!.id)!
                        .localitati
                    : [],
                  value: schoolData.localitate,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setSchoolData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        localitate: newValue,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.localitate,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        localitate: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.school.city'),
                }}
              />
            )}
          </div>
          {!isView && (
            <ButtonComp
              onButtonClick={() => {
                if (
                  !getStringValuesOfEnum(SchoolPageViewTypes).includes(
                    pageViewType
                  )
                )
                  return;
                schoolAction(_id!);
              }}
              buttonText={tCommon('inputs.saveButton')}
              buttonProps={{
                variant: 'contained',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )}
        </div>
      )}
    </div>
  );
};
