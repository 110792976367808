import { Dayjs } from 'dayjs';
import {
  City,
  Country,
  CountryWithCounties,
  County,
  IriEntity,
} from './Common';
import { School } from './School';
import { SubjectConfig } from './Subject';

export enum UserRoles {
  ROLE_SCHOOL_ADMIN = 'ROLE_SCHOOL_ADMIN',
  ROLE_SECRETAR = 'ROLE_SECRETAR',
  ROLE_DIRECTOR = 'ROLE_DIRECTOR',
  ROLE_DIRIGINTE = 'ROLE_DIRIGINTE',
  ROLE_INVATATOR = 'ROLE_INVATATOR',
  ROLE_PROFESOR = 'ROLE_PROFESOR',
  ROLE_PARINTE = 'ROLE_PARINTE',
  ROLE_ELEV = 'ROLE_ELEV',
  ROLE_USER = 'ROLE_USER',
}

export const rolesOptions = [
  {
    label: 'Admin scoala',
    value: UserRoles.ROLE_SCHOOL_ADMIN,
  },
  {
    label: 'Secretar',
    value: UserRoles.ROLE_SECRETAR,
  },
  {
    label: 'Director',
    value: UserRoles.ROLE_DIRECTOR,
  },
  {
    label: 'Diriginte',
    value: UserRoles.ROLE_DIRIGINTE,
  },
  {
    label: 'Invatator',
    value: UserRoles.ROLE_INVATATOR,
  },
  {
    label: 'Profesor',
    value: UserRoles.ROLE_PROFESOR,
  },
  {
    label: 'Parinte',
    value: UserRoles.ROLE_PARINTE,
  },
  {
    label: 'Elev',
    value: UserRoles.ROLE_ELEV,
  },
];

export interface UserPayload {
  email: string;
  roles: Array<string>;
  telefon: string;
  nume: string;
  prenume: string;
  initialaParinte: string;
  dataNastere: Dayjs | Date;
  cnp: string;
  nrMatricol: string;
  tara: string;
  judet: string;
  localitate: string;
  adresa: string;
  mentiuniMedicale?: string;
  cnpMama: string;
  cnpTata: string;
  scoala: string;

  // to remove after fixing on BE
  status: string;
  activ: boolean;
  password?: string;
  codUtilizator?: string;
  idUserScoala?: number;
}

export interface User extends IriEntity {
  id: number;
  tara: Country;
  judet: County;
  localitate: City;
  scoala: Partial<School>;
  email: string;
  roles: Array<UserRoles>;
  codUtilizator: string;
  telefon: string;
  nume: string;
  prenume: string;
  initialaParinte: string;
  dataNastere: Dayjs | Date;
  cnp: string;
  nrMatricol: string;
  adresa: string;
  mentiuniMedicale: string;
  cnpMama: string;
  cnpTata: string;
  rolActiv: UserRoles;
  numeComplet: string;
  materii: Array<{
    id: number;
    tipMaterie: {
      tipMaterie: string;
    };
  }>;
}

export interface UserModuleData {
  tara: Country | null;
  judet: County | null;
  localitate: City | null;
  email: string;
  roles: string[];
  telefon: string;
  nume: string;
  prenume: string;
  initialaParinte: string;
  dataNastere: Dayjs | null;
  cnp: string;
  nrMatricol: string;
  adresa: string;
  mentiuniMedicale?: string;
  cnpMama: string;
  cnpTata: string;
}

export interface UserResources {
  tari: CountryWithCounties[];
}

export interface Account {
  user: User;
  accessToken: string;
  selectedView: UserRoles;
  clasa: {
    codClasa: string;
    id: number;
  } | null;
}

export interface CollectionUser extends IriEntity {
  id: number;
  idUserScoala: number;
  nume: string;
  prenume: string;
  telefon: string;
  email: string;
  nrMatricol: string;
  activ: boolean;
  roles: Array<UserRoles>;
}

export interface InvatoriDirigintiClase extends IriEntity {
  id: number;
  prenume: string;
  nume: string;
  codUtilizator: string;
  clasaCurenta: string;
}
