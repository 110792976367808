import { Typography, useTheme } from '@mui/material';
import { UserContext } from 'context/UserContext';
import OutsideAlerter from 'library/hooks/useOutsideAlerter';
import { UserRoles } from 'library/types/User';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';
import { privateRoutes } from 'routes';

export interface MenuItemProps {
  label: string;
  submenu?: MenuItemProps[];
  icon?: string;
  route?: string;
  state?: {
    [key: string]: any;
  };
  allowedRoles?: Array<UserRoles>;
}

export const BurgerMenu = (props: {
  setIsBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBurgerOpen: boolean;
}) => {
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const location = useLocation();
  const currentRole = userContext.state.account!.selectedView;

  function updateTitlesAndCreateString(data: any) {
    const firstArray: any = [...privateRoutes];
    const updateTitleForRoute = (route: any, labelKey: any) => {
      const routeObj = firstArray.find((r: { path: any }) => r.path === route);
      if (routeObj) {
        routeObj.title = { default: labelKey };
      }
    };

    const iterateData = (items: any[]) => {
      items.forEach((item) => {
        if (item.route && item.label) {
          const labelKey = item.label;
          updateTitleForRoute(item.route, labelKey);
        }
        if (item.submenu && item.submenu.length > 0) {
          iterateData(item.submenu);
        }
      });
    };

    iterateData(data);

    // Creating a string representation
    const arrayAsString = firstArray
      .map(
        (route: {
          element: { type: { name: any } };
          title: any;
          allowedRoles: any;
          path: any;
        }) => {
          const elementString = route.element
            ? `element: <${route.element.type.name} />`
            : '';
          const titleString = route.title
            ? `title: ${JSON.stringify(route.title)}`
            : '';

          // Handling allowedRoles differently to maintain format
          const rolesString = route.allowedRoles
            ? `allowedRoles: [${route.allowedRoles
                .map((role: any) => `UserRoles.${role}`)
                .join(', ')}]`
            : '';

          return `{\n  path: '${route.path}',\n  ${elementString}${
            titleString ? ',\n  ' + titleString : ''
          }${rolesString ? ',\n  ' + rolesString : ''}\n}`;
        }
      )
      .join(',\n\n  ');
  }

  const data: MenuItemProps[] = [
    {
      label: 'burgerMenu.adminMenu',
      icon: icons.themes[userContext.state.theme].setari,
      allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],

      submenu: [
        {
          label: 'burgerMenu.adminSubmenus.userTable',
          route: '/users',
        },
        {
          label: 'burgerMenu.adminSubmenus.schoolEdit',
          route: '/individual-school/edit',
        },
        {
          label: 'burgerMenu.adminSubmenus.classStudentsTable',
          route: '/table-user-class',
        },
        {
          label: 'burgerMenu.adminSubmenus.timeSlotTable',
          route: '/time-slots',
        },
        {
          label: 'burgerMenu.adminSubmenus.schoolYearTable',
          route: '/school-years',
        },
        {
          label: 'burgerMenu.adminSubmenus.catedraTable',
          route: '/table-catedra',
        },
        {
          label: 'burgerMenu.adminSubmenus.classProfileTable',
          route: '/class-profiles',
        },
        {
          label: 'burgerMenu.adminSubmenus.specializationTable',
          route: '/table-class-specialization',
        },
        {
          label: 'burgerMenu.adminSubmenus.moduleTable',
          route: '/terms',
        },
        {
          label: 'burgerMenu.adminSubmenus.subjectTypeTable',
          route: '/subject-types',
        },
        {
          label: 'burgerMenu.adminSubmenus.subjectProfessorTable',
          route: '/subject-configs',
        },
      ],
    },
    {
      label: 'burgerMenu.academicRecords',
      icon: icons.themes[userContext.state.theme].clase,
      route: '/academic-records',
      allowedRoles: [
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_DIRECTOR,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ],
    },
    {
      label: 'burgerMenu.adminSubmenus.classTable',
      icon: icons.themes[userContext.state.theme].clase,
      submenu: [
        {
          label: 'burgerMenu.adminSubmenus.list',
          route: '/classes',
          allowedRoles: [
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRIGINTE,
            UserRoles.ROLE_SECRETAR,
            UserRoles.ROLE_DIRECTOR,
          ],
        },
        {
          label: 'burgerMenu.adminSubmenus.catalogReport',
          route: '/catalog-report',
          allowedRoles: [
            UserRoles.ROLE_SECRETAR,
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
          ],
        },
      ],
      allowedRoles: [
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_DIRIGINTE,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_DIRECTOR,
      ],
    },
    {
      label: 'burgerMenu.averageMenu',
      icon: icons.themes[userContext.state.theme].evaluari,
      allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
      submenu: [
        {
          label: 'burgerMenu.averageSubmenus.closeAverage',
          route: '/average',
          allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
        },
        // {
        //   label: 'burgerMenu.averageSubmenus.averageTablePrimary',
        //   route: '/average-table-primary',
        //   allowedRoles: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_SCHOOL_ADMIN],
        // },
        // {
        //   label: 'burgerMenu.averageSubmenus.averageTableNonPrimary',
        //   route: '/average-table-non-primary',
        //   allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_SCHOOL_ADMIN],
        // },
        // {
        //   label: 'burgerMenu.averageSubmenus.averageTableNonPrimary',
        //   route: '/average-table',
        //   allowedRoles: [
        //     UserRoles.ROLE_ELEV,
        //     UserRoles.ROLE_PARINTE,
        //     UserRoles.ROLE_SECRETAR,
        //     UserRoles.ROLE_SCHOOL_ADMIN,
        //     UserRoles.ROLE_DIRECTOR,
        //     UserRoles.ROLE_INVATATOR,
        //     UserRoles.ROLE_DIRIGINTE,
        //     UserRoles.ROLE_PROFESOR,
        //   ],
        // },
      ],
    },
    {
      label: 'burgerMenu.closingSituationMenu',
      icon: icons.themes[userContext.state.theme].evaluari,
      allowedRoles: [
        UserRoles.ROLE_ELEV,
        UserRoles.ROLE_PARINTE,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_DIRECTOR,
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_DIRIGINTE,
      ],
      submenu: [
        {
          label: 'burgerMenu.closingSituationSubmenus.closeSituation',
          route: '/close-situation',
          allowedRoles: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_DIRIGINTE],
        },
        {
          label: 'burgerMenu.closingSituationSubmenus.closedSituationTable',
          route: '/closed-situation-table',
          allowedRoles: [
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PARINTE,
            UserRoles.ROLE_SECRETAR,
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_DIRIGINTE,
          ],
        },
      ],
    },
    {
      label: 'burgerMenu.evaluationsMenu',
      icon: icons.themes[userContext.state.theme].evaluari,
      allowedRoles: [
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_ELEV,
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ],
      submenu: [
        {
          label: 'burgerMenu.evaluationsSubmenus.moduleQuestionsTable',
          route: '/evaluation-questions',
          allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.moduleQuestionConfigTable',
          route: '/evaluation-configurations',
          allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.moduleFillEvaluation',
          route: '/evaluation',
          allowedRoles: [
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
          ],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.moduleProfessorAnswersTable',
          route: '/professors-evaluation-responses',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
          ],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.moduleStudentsAnswersTable',
          route: '/students-evaluation-responses',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PARINTE,
          ],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.annualQuestionsTable',
          route: '/yearly-evaluation-questions',
          allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.annualFillEvaluation',
          route: '/yearly-evaluation',
          allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
        },
        {
          label: 'burgerMenu.evaluationsSubmenus.annualAnswersTable',
          route: '/yearly-evaluation-responses',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PARINTE,
          ],
        },
      ],
    },
    {
      label: 'burgerMenu.messagesMenu',
      icon: icons.themes[userContext.state.theme].mesaje,
      submenu: [
        {
          label: 'burgerMenu.messagesSubmenus.inbox',
          route: '/messages/inbox',
        },
        {
          label: 'burgerMenu.messagesSubmenus.outbox',
          route: '/messages/outbox',
        },
        {
          label: 'burgerMenu.messagesSubmenus.sendMessage',
          route: '/send-message/single',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_SECRETAR,
            UserRoles.ROLE_DIRIGINTE,
          ],
        },
        {
          label: 'burgerMenu.messagesSubmenus.sendBulkMessage',
          route: '/send-message/bulk',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_DIRECTOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_SECRETAR,
            UserRoles.ROLE_DIRIGINTE,
          ],
        },
      ],
    },
    {
      label: 'burgerMenu.timetableMenu',
      icon: icons.themes[userContext.state.theme].orar,
      route: '/view-timetable',
      allowedRoles: [
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_SECRETAR,
        UserRoles.ROLE_DIRECTOR,
      ],
    },
    {
      label: 'burgerMenu.detailedTimetableMenu',
      icon: icons.themes[userContext.state.theme].orar,
      route: '/view-detailed-timetable',
      allowedRoles: [
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_PARINTE,
        UserRoles.ROLE_ELEV,
        UserRoles.ROLE_DIRIGINTE,
      ],
    },
    {
      label: 'burgerMenu.lessonsMenu',
      icon: icons.themes[userContext.state.theme].lectii,
      allowedRoles: [
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_ELEV,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ],
      submenu: [
        {
          label: 'burgerMenu.lessonsSubmenus.lessonTable',
          route: '/table-lesson',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
        },
      ],
    },
    {
      label: 'burgerMenu.homeworkMenu',
      icon: icons.themes[userContext.state.theme].teme,
      allowedRoles: [
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_ELEV,
        // UserRoles.ROLE_PARINTE,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ],
      submenu: [
        {
          label: 'burgerMenu.homeworkSubmenus.homeworkTable',
          route: '/table-homework',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
        },
        {
          label: 'burgerMenu.homeworkSubmenus.homeworkSubmittedTable',
          route: '/table-student-homework/solved',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
          state: {
            queryParams: {
              statusTemaElev: 1,
            },
            overrideTitle: 'burgerMenu.homeworkSubmenus.homeworkSubmittedTable',
          },
        },
        {
          label: 'burgerMenu.homeworkSubmenus.homeworkReviewedTable',
          route: '/table-student-homework/reviewed',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_PROFESOR,
            // UserRoles.ROLE_PARINTE,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
          state: {
            queryParams: {
              statusTemaElev: 2,
            },
            overrideTitle: 'burgerMenu.homeworkSubmenus.homeworkReviewedTable',
          },
        },
      ],
    },
    {
      label: 'burgerMenu.testsMenu',
      icon: icons.themes[userContext.state.theme].teste,
      allowedRoles: [
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_ELEV,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ],
      submenu: [
        {
          label: 'burgerMenu.testsSubmenus.testQuestionTable',
          route: '/table-test-question',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
        },
        {
          label: 'burgerMenu.testsSubmenus.testTableStudents',
          route: '/table-test/students',
          allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN, UserRoles.ROLE_ELEV],
        },
        {
          label: 'burgerMenu.testsSubmenus.testTableProfessors',
          route: '/table-test/professors',
          allowedRoles: [
            UserRoles.ROLE_SCHOOL_ADMIN,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_INVATATOR,
          ],
        },

        {
          label: 'burgerMenu.testsSubmenus.testQuestionToTestTable',
          route: '/table-test-question-to-test',
          allowedRoles: [
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
        },
        {
          label: 'burgerMenu.testsSubmenus.testQuestionAnswerTable',
          route: '/table-test-question-answer',
          allowedRoles: [
            UserRoles.ROLE_ELEV,
            UserRoles.ROLE_INVATATOR,
            UserRoles.ROLE_PROFESOR,
            UserRoles.ROLE_SCHOOL_ADMIN,
          ],
        },
      ],
    },
    {
      label: 'burgerMenu.catalogueMenu',
      icon: icons.themes[userContext.state.theme].catalog,
      route: '/catalogue-entries',
    },
    {
      label: 'burgerMenu.profileMenu',
      icon: icons.themes[userContext.state.theme].profile,
      route: '/profile',
    },
  ];

  const { setIsBurgerOpen, isBurgerOpen } = props;

  const [openSubmenusPath, setOpenSubmenusPath] = useState<string[]>([]);

  const toggleSubmenu = (path: string[]) => {
    if (
      path.length === openSubmenusPath.length &&
      path.every((v, i) => v === openSubmenusPath[i])
    ) {
      setOpenSubmenusPath([]);
    } else {
      setOpenSubmenusPath(path);
    }
  };
  return (
    <OutsideAlerter
      action={() => {
        setIsBurgerOpen(false);
        setOpenSubmenusPath([]);
      }}
      children={
        <div
          style={{
            top: '30px',
            left: '64px',
            zIndex: 999,
          }}
          className="flex flex-col absolute"
        >
          <div
            onClick={() =>
              setIsBurgerOpen((prev) => {
                return !prev;
              })
            }
            className={`flex flex-col w-12 gap-2 justify-between cursor-pointer`}
          >
            <div
              style={{
                backgroundColor:
                  location.pathname === '/home'
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
              }}
              className="w-full h-1"
            ></div>
            <div
              style={{
                backgroundColor:
                  location.pathname === '/home'
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
              }}
              className="w-full h-1"
            ></div>
            <div
              style={{
                backgroundColor:
                  location.pathname === '/home'
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
              }}
              className="w-full h-1"
            ></div>
          </div>
          <div
            style={{
              backgroundColor: 'rgb(241, 245, 248)',
            }}
            className={`flex-col mt-4 shadow-xl ${
              isBurgerOpen ? 'flex' : 'hidden'
            }`}
          >
            {data
              .filter((item) =>
                !item.allowedRoles
                  ? true
                  : item.allowedRoles.includes(currentRole)
              )
              .map((item, index) => (
                <MenuItem
                  key={item.label}
                  {...item}
                  toggleSubmenu={toggleSubmenu}
                  openSubmenusPath={openSubmenusPath}
                  setOpenSubmenusPath={setOpenSubmenusPath}
                  path={[`${index}`]}
                  setIsBurgerOpen={setIsBurgerOpen}
                />
              ))}
          </div>
        </div>
      }
    />
  );
};

interface MenuItemComponentProps extends MenuItemProps {
  toggleSubmenu: (path: string[]) => void;
  openSubmenusPath: string[];
  path: string[];
  setOpenSubmenusPath: React.Dispatch<React.SetStateAction<string[]>>;
  setIsBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  state?: {
    [key: string]: any;
  };
}

const MenuItem: FC<MenuItemComponentProps> = ({
  label,
  submenu,
  icon,
  toggleSubmenu,
  openSubmenusPath,
  path,
  route,
  setOpenSubmenusPath,
  setIsBurgerOpen,
  state,
}) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const currentRole = userContext.state.account!.selectedView;
  const isSubmenuOpen =
    openSubmenusPath.length >= path.length &&
    path.every((v, i) => v === openSubmenusPath[i]);

  const handleClick = () => {
    if (submenu) {
      return toggleSubmenu(path);
    } else if (route) {
      setIsBurgerOpen(false);
      setOpenSubmenusPath([]);
      navigate(route, {
        state,
      });
    }
  };
  return (
    <div className="relative">
      <div
        style={{
          backgroundColor: 'rgb(241, 245, 248)',
          minWidth: '340px',
          width: 'fit-content',
        }}
        onClick={handleClick}
        className="flex cursor-pointer h-12 border-2 shadow-xl select-none"
      >
        {!!icon && (
          <div className="flex h-full items-center justify-center w-28">
            <img className="h-auto w-100" src={icon} width={25} />
          </div>
        )}
        <div
          className={`flex h-full w-full items-center pr-4 ${
            !icon ? 'pl-4' : ''
          }`}
        >
          <Typography
            color={{ color: theme.palette.secondaries.text.main }}
            variant="headerLight"
          >
            {t(label)}
          </Typography>
        </div>
      </div>
      {submenu && isSubmenuOpen && (
        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '516px',
          }}
          className="absolute left-full top-0"
        >
          {submenu
            .filter((subItem) =>
              !subItem.allowedRoles
                ? true
                : subItem.allowedRoles.includes(currentRole)
            )
            .map((subItem, index) => (
              <MenuItem
                key={subItem.label}
                {...subItem}
                toggleSubmenu={toggleSubmenu}
                openSubmenusPath={openSubmenusPath}
                setOpenSubmenusPath={setOpenSubmenusPath}
                path={[...path, `${index}`]}
                setIsBurgerOpen={setIsBurgerOpen}
              />
            ))}
        </div>
      )}
    </div>
  );
};
