import { Typography } from '@mui/material';
import { Footer } from 'common/Footer/Footer';
import { getViewTypeString } from 'library/helpers/helper';
import { Title } from 'library/types/Common';
import { useTranslation } from 'react-i18next';

interface RouteWrapperProps {
  children: JSX.Element;
  isCentered?: boolean;
  title: Title | string;
  path: string;
  noPageContainer?: boolean;
}

export const RouteWrapper = (props: RouteWrapperProps) => {
  const { t } = useTranslation('common');
  const { children, isCentered, title, path, noPageContainer } = props;
  if (noPageContainer) return children;

  return (
    <div className="flex flex-col">
      <div style={{ height: '224px' }}></div>

      <div
        style={{ minHeight: 'calc(100vh - 9rem - 80px)' }}
        className={`flex flex-col w-full relative`}
      >
        <div
          style={{ top: '-60px' }}
          className="absolute flex w-full justify-center"
        >
          <Typography variant="h3">
            {typeof title === 'string'
              ? title
              : getViewTypeString(path) === 'default'
              ? t(title.default || '')
              : t(`${t(title[getViewTypeString(path)] || '')}`)}
          </Typography>
        </div>
        <div
          className={`flex p-12 pt-6 ${
            path === '/home' ? 'pb-0' : ''
          } flex-1 w-full ${isCentered ? 'justify-center items-center' : ''}`}
        >
          {children}
        </div>
        {path === '/home' && <Footer />}
      </div>
    </div>
  );
};
